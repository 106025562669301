import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModalAddPrescriptionSelector } from '../../features/ui/selector'
import { setOpenModalAddPrescription } from '../../features/ui/action'
import { Trans, useTranslation } from 'next-i18next'
import {
  FreeLabel,
  HelpLink,
  PanelDivider,
  PDContainer,
  PrescriptionInfoPanel,
  PrescriptionSection,
  RangeSelector,
  RangeSelectorContainer,
  RowsBlock,
  RowsBlockMobile,
  RXBlock,
  RXBlockMobile,
  SaveCTA,
  SavePrescriptionContainer,
  SavePrescriptionCTAWrapper,
  SavePrescriptionModalDialogTitle,
  StyledMenuItem,
  StyledModalDialog,
  StyledTextField,
  StyledTextFieldWrapper,
} from './Prescriptions.style'
import useBreakpoints from '../../hooks/useBreakpoints'
import { StyledAlert, StyledAnchor, StyledCheckbox, StyledFormControlLabel, StyledTooltip } from '@components/UI'
import { Controller, useForm } from 'react-hook-form'
import { useYupValidationResolver } from '../../utils/validationResolver'
import { buildYupValidationSchema } from '../../constants/form'
import {
  getAddRange,
  getAxisRange,
  getPDRange,
  getNegativeRanges,
  getPositiveRanges,
  PRESCRIPTION_FORM_FIELDS,
} from '../../constants/prescription'
import { isEmpty, toNumber } from 'lodash-es'
import { IPrescription, useAddOrUpdatePrescriptionMutation } from '../../features/prescription/query'
import { SplitRangeSelect } from '../../components/UI/SplitRangeSelect'
import { StyledAlertHeader, StyledAlertMessage } from '../../components/UI/Alert'
import { IAlert } from '../../components/UI/Alert/Alert'
import { userDetailsSelector } from '../../redux/selectors/user'
import { PrescriptionFormData } from '../../types/prescription'
import { useTheme } from 'styled-components'
import { validateRx } from '../../components/PrescriptionLenses/RxUtils'
import { useSite } from '@foundation/hooks/useSite'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

import styles from './styles/index.module.scss'
import { COUNTRY_MAP } from '@constants/common'

const SavePrescriptionDialog: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const [isTwoPDSelected, setTwoPDSelected] = useState<boolean>(false)
  const [hasRightAxisError, setHasRightAxisError] = useState<boolean>(false)
  const [hasLeftAxisError, setHasLeftAxisError] = useState<boolean>(false)
  const [bodyData, setBodyData] = useState<object | undefined>()
  const [addOrUpdatePrescriptionMutation] = useAddOrUpdatePrescriptionMutation()
  const dialogProps = useSelector(openModalAddPrescriptionSelector)
  let prescription = dialogProps.prescription
  const [newNickName, setNewNickName] = useState('')
  const [alert, setAlert] = useState<IAlert | null>(null)
  const userDetails = useSelector(userDetailsSelector)
  const rangeSelectorMenuProps = {
    PaperProps: {
      sx: {
        maxHeight: 250,
        marginTop: '-10px',
        '& .MuiMenuItem-root:hover': {
          backgroundColor: theme?.palette?.custom.blue,
          color: theme?.palette?.custom.white,
        },
      },
    },
  }
  const DEFAULT_ONE_PD_PUP_DISTANCE = '63.0'
  const DEFAULT_TWO_PDS_PUP_DISTANCE = '23.0'
  const DEFAULT_FIELD_NO_VALUE = '0.00'
  const DEFAULT_PD_FIELD_NO_VALUE = '0.0'
  const DEFAULT_AXIS_FIELD_NO_VALUE = '0'
  const requireIssueDate: boolean = (
    mySite?.country === COUNTRY_MAP.AUSTRALIA || 
    mySite?.country === COUNTRY_MAP.NEW_ZEALAND
  )
  
  const negativeSphereRangeParams = {
    min: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.SPHERE_NEGATIVE_MIN ?? -0.25,
    max: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.SPHERE_NEGATIVE_MAX ?? -20
  }

  const positiveSphereRangeParams = {
    min: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.SPHERE_POSITIVE_MIN ?? 0.25,
    max: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.SPHERE_POSITIVE_MAX ?? 20
  }
  
  const negativeCylinderRangeParams = {
    min: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.CYLINDER_NEGATIVE_MIN ?? -0.25,
    max: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.CYLINDER_NEGATIVE_MAX ?? -20
  }

  const positiveCylinderRangeParams = {
    min: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.CYLINDER_POSITIVE_MIN ?? 0.25,
    max: mySite.xStoreCfg?.PRESCRIPTION_RANGE?.CYLINDER_POSITIVE_MAX ?? 20
  }

  const handleClose = () => {
    prescription = null
    setAlert(null)
    setDefaultRxValues()
    dispatch(setOpenModalAddPrescription(false, null, null))
  }
  const defaultValues: PrescriptionFormData = {
    nickName: '',
    rightSphere: DEFAULT_FIELD_NO_VALUE,
    rightAdd: DEFAULT_FIELD_NO_VALUE,
    rightAxis: DEFAULT_AXIS_FIELD_NO_VALUE,
    rightCyl: DEFAULT_FIELD_NO_VALUE,
    leftSphere: DEFAULT_FIELD_NO_VALUE,
    leftAdd: DEFAULT_FIELD_NO_VALUE,
    leftAxis: DEFAULT_AXIS_FIELD_NO_VALUE,
    leftCyl: DEFAULT_FIELD_NO_VALUE,
    lPupDistance: DEFAULT_FIELD_NO_VALUE,
    rPupDistance: DEFAULT_ONE_PD_PUP_DISTANCE,
    pupillaryDistance: DEFAULT_FIELD_NO_VALUE,
    ...(requireIssueDate ? { issue: false } : {}),
  }

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    setError
  } = useForm<PrescriptionFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: useYupValidationResolver(
      buildYupValidationSchema({
        formFields: PRESCRIPTION_FORM_FIELDS,
        i18nInvalidMsgBase: 'PrescriptionDialog.Msgs.',
        i18nFormFieldsInvalidMsgs: {},
        t,
      })
    ),
  })

  const setErrorMessage = error => {
    setAlert(
      (error && {
        type: 'error',
        title: error?.title || t('PrescriptionsSection.AddSection.Error.GenericError.Title'),
        message: error?.message || t('PrescriptionsSection.AddSection.Error.GenericError.Description'),
      }) ||
        null
    )
  }

  useEffect(() => {
    if (bodyData) {
      addOrUpdatePrescriptionMutation(bodyData)
        .unwrap()
        .then(result => {
          if (result.errorCode) {
            setErrorMessage(result.errorMessage)
          } else {
            const type = prescription?.idXprescription ? 'Updated' : 'Added'
            const action = t(`PrescriptionsSection.${type}`)

            if (dialogProps?.onActionSuccess) {
              dialogProps.onActionSuccess({
                type: 'success',
                title: t('PrescriptionsSection.PrescriptionActionResultTitle', {
                  action: action,
                }),
                description: t('PrescriptionsSection.PrescriptionActionResultMessage', {
                  nickName: newNickName,
                  action: action,
                }),
              })
            }
            handleClose()
          }
        })
        .catch(error => {
          setErrorMessage(error)
        })
    }
  }, [bodyData])

  useEffect(() => {
    setHasLeftAxisError(false)
    setHasRightAxisError(false)

    if (prescription) {
      const twoPdSelected = prescription.pupillaryDistance === DEFAULT_PD_FIELD_NO_VALUE
      setTwoPDSelected(twoPdSelected)
      setValue('rPupDistance', twoPdSelected ? prescription?.rPupDistance : prescription.pupillaryDistance)
      setValue('lPupDistance', prescription.lPupDistance)
      setValue('rightSphere', prescription.rightSphere)
      setValue('rightAdd', toNumber(prescription.rightAdd).toFixed(2))
      setValue('rightAxis', `${Math.round(toNumber(prescription.rightAxis))}`)
      setValue('rightCyl', prescription.rightCylinder)
      setValue('leftSphere', prescription.leftSphere)
      setValue('leftAdd', toNumber(prescription.leftAdd).toFixed(2))
      setValue('leftAxis', `${Math.round(toNumber(prescription.leftAxis))}`)
      setValue('leftCyl', prescription?.leftCylinder)
      setValue('nickName', prescription.nickName)
      if (requireIssueDate) {
        setValue('issue', false)
      }
    } else {
      setDefaultRxValues()
    }
    validateForm(prescription)
  }, [prescription])

  useEffect(() => {
    setValue(
      'rPupDistance',
      isTwoPDSelected
        ? prescription?.rPupDistance || DEFAULT_TWO_PDS_PUP_DISTANCE
        : prescription?.pupillaryDistance || DEFAULT_ONE_PD_PUP_DISTANCE
    )
    setValue(
      'lPupDistance',
      isTwoPDSelected ? prescription?.lPupDistance || DEFAULT_TWO_PDS_PUP_DISTANCE : DEFAULT_TWO_PDS_PUP_DISTANCE
    )
  }, [isTwoPDSelected])

  const setDefaultRxValues = () => {
    setValue('rPupDistance', DEFAULT_ONE_PD_PUP_DISTANCE)
    setValue('lPupDistance', DEFAULT_PD_FIELD_NO_VALUE)
    setValue('rightSphere', DEFAULT_FIELD_NO_VALUE)
    setValue('rightAdd', DEFAULT_FIELD_NO_VALUE)
    setValue('rightAxis', DEFAULT_AXIS_FIELD_NO_VALUE)
    setValue('rightCyl', DEFAULT_FIELD_NO_VALUE)
    setValue('leftSphere', DEFAULT_FIELD_NO_VALUE)
    setValue('leftAdd', DEFAULT_FIELD_NO_VALUE)
    setValue('leftAxis', DEFAULT_AXIS_FIELD_NO_VALUE)
    setValue('leftCyl', DEFAULT_FIELD_NO_VALUE)
    setValue('nickName', '')
    setTwoPDSelected(false)
    if (requireIssueDate) {
      setValue('issue', false)
    }
  }

  const handleFieldChange = (field, newValue) => {
    setValue(field, newValue)
    validateForm(getValues())
  }

  const validateForm = (data: PrescriptionFormData | IPrescription | null) => {
    const { leftAxisError, rightAxisError } = validateRx(data)
    const hasErrors = leftAxisError || rightAxisError
    setHasLeftAxisError(leftAxisError)
    setHasRightAxisError(rightAxisError)

    setErrorMessage(
      hasErrors
        ? {
            title: t('PrescriptionsSection.AddSection.Error.AxisError.Title'),
            message: t('PrescriptionsSection.AddSection.Error.AxisError.Description'),
          }
        : null
    )

    return !hasErrors
  }

  const onSubmit = (data: PrescriptionFormData) => {
    const {
      nickName,
      rightSphere,
      rightAdd,
      rightAxis,
      rightCyl,
      leftSphere,
      leftAdd,
      leftAxis,
      leftCyl,
      lPupDistance,
      rPupDistance
    } = data

    setErrorMessage(null)

    const isValid = (validateForm(data) && (!requireIssueDate || data?.issue))

    if (isValid) {
      const body = {
        storeId: mySite.storeID,
        prescription: {
          id: prescription?.idXprescription ? `${prescription?.idXprescription}` : null,
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          telephone: userDetails?.phone1,
          nickName: nickName?.trim() || userDetails?.firstName,
          rightSphere,
          rightAdd,
          rightAxis,
          rightCyl,
          leftSphere,
          leftAdd,
          leftAxis,
          leftCyl,
          lPupDistance: isTwoPDSelected ? lPupDistance : DEFAULT_FIELD_NO_VALUE,
          rPupDistance: isTwoPDSelected ? rPupDistance : DEFAULT_FIELD_NO_VALUE,
          pupillaryDistance: isTwoPDSelected ? DEFAULT_FIELD_NO_VALUE : rPupDistance,
          ...(requireIssueDate ? { issue: new Date(Date.now()).toLocaleDateString('en-GB') } : {}),
        },
      }
      setNewNickName(nickName || '')
      setTimeout(() => setBodyData(body), 100)
    } else {
      setError('issue', { message: 'required' }, { shouldFocus: true })
    }
  }

  const rightSphereFieldController = (
    <Controller
      render={({ field: { ...rest } }) => (
        <SplitRangeSelect
          {...rest}
          required
          leftSideItems={getNegativeRanges(negativeSphereRangeParams)}
          rightSideItems={getPositiveRanges(positiveSphereRangeParams)}
          value={toNumber(prescription?.rightSphere || 0)}
          mainItem={0}
          decimaFractionalParts={2}
          onValueChange={newValue => setValue('rightSphere', newValue)}
        />
      )}
      name="rightSphere"
      control={control}
    />
  )

  const leftSphereFieldController = (
    <Controller
      render={({ field: { ...rest } }) => (
        <SplitRangeSelect
          {...rest}
          value={toNumber(prescription?.leftSphere || 0)}
          required
          leftSideItems={getNegativeRanges(negativeSphereRangeParams)}
          rightSideItems={getPositiveRanges(positiveSphereRangeParams)}
          mainItem={0}
          decimaFractionalParts={2}
          onValueChange={newValue => setValue('leftSphere', newValue)}
        />
      )}
      name="leftSphere"
      control={control}
    />
  )

  const rightCylinderFieldController = (
    <Controller
      render={({ field: { ...rest } }) => (
        <SplitRangeSelect
          {...rest}
          value={toNumber(prescription?.rightCylinder || 0)}
          required
          leftSideItems={getNegativeRanges(negativeCylinderRangeParams)}
          rightSideItems={getPositiveRanges(positiveCylinderRangeParams)}
          onValueChange={newValue => handleFieldChange('rightCyl', newValue)}
          mainItem={0}
          decimaFractionalParts={2}
        />
      )}
      name="rightCyl"
      control={control}
    />
  )
  const leftCylinderFieldController = (
    <Controller
      render={({ field: { ...rest } }) => (
        <SplitRangeSelect
          {...rest}
          value={toNumber(prescription?.leftCylinder || 0)}
          required
          leftSideItems={getNegativeRanges(negativeCylinderRangeParams)}
          rightSideItems={getPositiveRanges(positiveCylinderRangeParams)}
          mainItem={0}
          decimaFractionalParts={2}
          onValueChange={newValue => handleFieldChange('leftCyl', newValue)}
        />
      )}
      name="leftCyl"
      control={control}
    />
  )

  const rightAxisFieldController = (
    <Controller
      render={({ field: { value, ...rest } }) => (
        <RangeSelector
          {...rest}
          error={hasRightAxisError}
          value={value}
          onChange={evt => handleFieldChange('rightAxis', evt.target.value)}
          required
          MenuProps={rangeSelectorMenuProps}
          className="range-selector"
        >
          {getAxisRange().map((range, i) => (
            <StyledMenuItem key={i} selected={range.toString() === prescription?.rightAxis} value={range}>
              {range}
            </StyledMenuItem>
          ))}
        </RangeSelector>
      )}
      name="rightAxis"
      control={control}
    />
  )
  const leftAxisFieldController = (
    <Controller
      render={({ field: { value, ...rest } }) => (
        <RangeSelector
          {...rest}
          error={hasLeftAxisError}
          onChange={evt => handleFieldChange('leftAxis', evt.target.value)}
          value={value}
          required
          MenuProps={rangeSelectorMenuProps}
          className="range-selector"
        >
          {getAxisRange().map((range, i) => (
            <StyledMenuItem key={i} selected={range.toString() === prescription?.leftAxis} value={range}>
              {range}
            </StyledMenuItem>
          ))}
        </RangeSelector>
      )}
      name="leftAxis"
      control={control}
    />
  )

  const rightAddFieldController = (
    <Controller
      render={({ field: { value, ...rest } }) => (
        <RangeSelector {...rest} value={value} MenuProps={rangeSelectorMenuProps} className="range-selector">
          {getAddRange().map((range, i) => (
            <StyledMenuItem key={i} selected={range === prescription?.rightAdd} value={range}>
              {range}
            </StyledMenuItem>
          ))}
        </RangeSelector>
      )}
      name="rightAdd"
      control={control}
    />
  )
  const leftAddFieldController = (
    <Controller
      render={({ field: { value, ...rest } }) => (
        <RangeSelector {...rest} value={value} MenuProps={rangeSelectorMenuProps} className="range-selector">
          {getAddRange().map((range, i) => (
            <StyledMenuItem key={i} selected={range === prescription?.leftAdd} value={range}>
              {range}
            </StyledMenuItem>
          ))}
        </RangeSelector>
      )}
      name="leftAdd"
      control={control}
    />
  )

  const DesktopPrescriptionInfoPanel = (
    <PrescriptionInfoPanel>
      <RowsBlock className="od-os-label-block">
        <RXBlock className="labels-container">
          <FreeLabel> </FreeLabel>
          <FreeLabel className="od-os-label">{t('PrescriptionsSection.AddNew.OD')}</FreeLabel>
          <FreeLabel className="od-os-label">{t('PrescriptionsSection.AddNew.OS')}</FreeLabel>
        </RXBlock>
      </RowsBlock>
      <RowsBlock>
        <RXBlock>
          <FreeLabel> {t('PrescriptionsSection.AddNew.SPH')}</FreeLabel>
          {rightSphereFieldController}
          {leftSphereFieldController}
        </RXBlock>
      </RowsBlock>
      <RowsBlock>
        <RXBlock>
          <FreeLabel> {t('PrescriptionsSection.AddNew.CYL')}</FreeLabel>
          {rightCylinderFieldController}
          {leftCylinderFieldController}
        </RXBlock>
      </RowsBlock>
      <RowsBlock>
        <RXBlock>
          <FreeLabel>{t('PrescriptionsSection.AddNew.Axis')}</FreeLabel>
          {rightAxisFieldController}
          {leftAxisFieldController}
        </RXBlock>
      </RowsBlock>
      <RowsBlock>
        <RXBlock>
          <FreeLabel>{t('PrescriptionsSection.AddNew.ADD')}</FreeLabel>
          {rightAddFieldController}
          {leftAddFieldController}
        </RXBlock>
      </RowsBlock>
    </PrescriptionInfoPanel>
  )

  const MobilePrescriptionInfoPanel = (
    <PrescriptionInfoPanel>
      <RowsBlockMobile className="od-os-label-block">
        <RXBlock className="labels-container">
          <FreeLabel> {t('PrescriptionsSection.AddNew.SPH')}</FreeLabel>
          <FreeLabel> {t('PrescriptionsSection.AddNew.CYL')}</FreeLabel>
          <FreeLabel>{t('PrescriptionsSection.AddNew.Axis')}</FreeLabel>
          <FreeLabel>{t('PrescriptionsSection.AddNew.ADD')}</FreeLabel>
        </RXBlock>
      </RowsBlockMobile>
      <RowsBlockMobile>
        <RXBlockMobile>
          <FreeLabel className="od-os-label">{t('PrescriptionsSection.AddNew.OD')}</FreeLabel>
          {rightSphereFieldController}
          {rightCylinderFieldController}
          {rightAxisFieldController}
          {rightAddFieldController}
        </RXBlockMobile>
      </RowsBlockMobile>
      <RowsBlockMobile>
        <RXBlockMobile>
          <FreeLabel className="od-os-label">{t('PrescriptionsSection.AddNew.OS')}</FreeLabel>
          {leftSphereFieldController}
          {leftCylinderFieldController}
          {leftAxisFieldController}
          {leftAddFieldController}
        </RXBlockMobile>
      </RowsBlockMobile>
    </PrescriptionInfoPanel>
  )

  return (
    <StyledModalDialog
      open={dialogProps.isOpen}
      onClose={handleClose}
      onCloseButton={handleClose}
      widthSize={'sm'}
      isSaveRxModal={true}
    >
      <>
        <SavePrescriptionContainer>
          <SavePrescriptionModalDialogTitle>{t('PrescriptionsSection.AddNew.Title')}</SavePrescriptionModalDialogTitle>
          <HelpLink
            className={'prescription-page__how-to__link--active'}
            target="_"
            href={t('PrescriptionsSection.AddNew.HowToReadYourPrescription.Link')}
          >
            {t('PrescriptionsSection.AddNew.HowToReadYourPrescription.Text')}
          </HelpLink>

          <form noValidate name="addPrescriptionForm" id={'addPrescriptionForm'} onSubmit={handleSubmit(onSubmit)}>
            <StyledTextFieldWrapper>
              <Controller
                render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                  <StyledTextField
                    {...rest}
                    inputRef={ref}
                    label={t('PrescriptionsSection.AddNew.PrescriptionName')}
                    type="text"
                    required
                    value={value}
                    error={(error && !!value?.length) || (error && isEmpty(value))}
                    helperText={
                      ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.nickName?.message ?? '')
                    }
                    showvalidationstatus
                    isvalid={!error && !!value?.length}
                    ispristine={!value?.length}
                  />
                )}
                name="nickName"
                control={control}
              />
            </StyledTextFieldWrapper>
            <PrescriptionSection>
              {isMobile ? MobilePrescriptionInfoPanel : DesktopPrescriptionInfoPanel}

              <PanelDivider />

              <PDContainer className="pd-container">
                <FreeLabel className="pd-label">
                  {t('PrescriptionsSection.AddNew.PD')}
                  <HelpLink
                    className={'prescription-page-what-is-pd-link'}
                    target="_"
                    href={t('PrescriptionsSection.AddNew.WhatIsPD.Link')}
                  >
                    {t('PrescriptionsSection.AddNew.WhatIsPD.Text')}
                  </HelpLink>
                </FreeLabel>

                <RangeSelectorContainer className="puppilary-distance-container">
                  <FreeLabel className="od-os-labels">
                    {isTwoPDSelected ? t('PrescriptionsSection.AddNew.Right') : null}
                  </FreeLabel>
                  <Controller
                    render={({ field: { value, ...rest } }) => (
                      <RangeSelector
                        {...rest}
                        value={value}
                        required
                        MenuProps={rangeSelectorMenuProps}
                        className="range-selector pd-range-selector pd-range-selector-right"
                        label=""
                      >
                        {getPDRange(isTwoPDSelected).map((range, i) => (
                          <StyledMenuItem
                            key={i}
                            selected={range.toFixed(1) === prescription?.rPupDistance}
                            value={range.toFixed(1)}
                          >
                            {range.toFixed(1)}
                          </StyledMenuItem>
                        ))}
                      </RangeSelector>
                    )}
                    name="rPupDistance"
                    control={control}
                  />
                  {(isTwoPDSelected && (
                    <>
                      <FreeLabel className="od-os-labels">{t('PrescriptionsSection.AddNew.Left')}</FreeLabel>
                      <Controller
                        render={({ field: { value, ...rest } }) => (
                          <RangeSelector
                            {...rest}
                            value={value}
                            MenuProps={rangeSelectorMenuProps}
                            className="range-selector pd-range-selector pd-range-selector-left"
                            label=""
                          >
                            {getPDRange(isTwoPDSelected).map((range, i) => (
                              <StyledMenuItem
                                key={i}
                                selected={range.toFixed(1) === prescription?.lPupDistance}
                                value={range.toFixed(1)}
                              >
                                {range.toFixed(1)}
                              </StyledMenuItem>
                            ))}
                          </RangeSelector>
                        )}
                        name="lPupDistance"
                        control={control}
                      />
                    </>
                  )) ||
                    null}
                  <StyledFormControlLabel
                    control={<StyledCheckbox checked={isTwoPDSelected} onChange={() => setTwoPDSelected(v => !v)} />}
                    label={t('PrescriptionsSection.AddNew.HaveTwoPDs')}
                  />
                </RangeSelectorContainer>
              </PDContainer>
              { requireIssueDate &&
                <div className={clsx(styles['prescription-validation'])}>
                  <Controller
                  render={({ field: { value, ref, ...rest }, fieldState: { error, isTouched } }) => {

                      return (
                      <label
                        className={error && clsx(styles['required'])}
                      >
                        <StyledCheckbox 
                            {...rest}
                            required
                            value={true}
                            ref={ref}
                            className={(isTouched && error) ? clsx(styles['required']) : ''}
                          />
                          {t('PrescriptionsSection.AddNew.ValidPrescription.Label')}
                      </label>
                    )
                  }}
                  name="issue"
                  control={control}
                />
                  <StyledTooltip
                    contentVariant={{
                      type: 'textonly',
                      body: t('PrescriptionsSection.AddNew.ValidPrescription.ToolTip'),
                    }}
                    colorVariant={'white'}
                    placement={'top'}
                  >
                    <span>
                      <SVGIcon library="validation" name="info" color={'#000'} />
                    </span>
                  </StyledTooltip>
                </div>
              }
              <PanelDivider />
              {alert && (
                <StyledAlert
                  onClose={() => setAlert(null)}
                  severity={alert.type}
                  sx={{ width: '97%' }}
                  icon={<SVGIcon library="close" name="close-circle" />}
                >
                  <StyledAlertHeader>{alert.title}</StyledAlertHeader>
                  <StyledAlertMessage fullWidth>
                    <span dangerouslySetInnerHTML={{ __html: alert.message }} />
                  </StyledAlertMessage>
                </StyledAlert>
              )}

              <SavePrescriptionCTAWrapper>
                <SaveCTA data-element-id="X_X_AddPrescription_Submit" type="submit" variant="primary">
                  {t('PrescriptionsSection.AddNew.CTA')}
                </SaveCTA>
                <FreeLabel className="prescription-questions">
                  <Trans i18nKey="PrescriptionsSection.AddNew.Questions">
                    {{
                      callUs: t('PrescriptionsSection.AddNew.CallUs'),
                      EmailUs: t('PrescriptionsSection.AddNew.EmailUs'),
                    }}
                    <StyledAnchor external={true} href={t('PrescriptionsSection.AddNew.SupportPhone')} />
                    <StyledAnchor external={true} href={t('PrescriptionsSection.AddNew.SupportEmail')} />
                  </Trans>
                </FreeLabel>
              </SavePrescriptionCTAWrapper>
            </PrescriptionSection>
          </form>
        </SavePrescriptionContainer>
      </>
    </StyledModalDialog>
  )
}

export default SavePrescriptionDialog
