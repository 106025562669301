import React, { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import {
  AddToCartButton,
  AvailabilityTitle,
  AvailabilityWarning,
  BrowseSimilarItemsContainer,
  ComingSoonWarning,
  LineSeparator,
  NeedPrescriptionText,
  PdpCtasWrapper,
  SoldOutContainer,
  StyledButtonsContainer,
  StyledRelativeBtn,
} from './CtaPdp.style'
import { ProductSoldOutStatus, IProduct } from '@typesApp/product'
import { PRODUCT_SOLDOUT_STATUS } from '../../../../constants/product'
import { PreLoader, StyledButtonAsLink } from '../../../../components/UI'
import { getProductsPartNumbers, isCLAccessoriesProduct, isOpticalProduct } from '../../../../utils/product'
import { openDrawerProductNotificationSelector } from '../../../../features/ui/selector'
import { setOpenDrawerProductNotificationAvailable } from '../../../../features/ui/action'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { addToCartBusySelector } from '../../../../features/cartui/cartuiSlice'
import {
  prescriptionLensDataLoadingSelector,
  prescriptionLensFormOpenSelector,
  setPrescriptionLensFormOpen,
} from '../../../../features/rox/roxSlice'
import {
  addCLAccessoriesToCartErrorSelector,
  addContactLensesToCartErrorSelector,
  addSubscriptionToCartErrorSelector,
  productAddToCartEnabledSelector,
} from '../../../../features/product/selector'
import {
  setCurrentProductBundle,
  toggleAddContactLensesToCartError,
  toggleAddSubscriptionToCartError,
} from '../../../../features/product/slice'
import { isSoldOutFeatureEnabled } from '../../../../utils/common'
import useScrollTo from '@hooks/useScrollTo'
import { initIndexName } from '@foundation/algolia/algoliaConfig'
import { localeLangCountryUtil } from '@utils/countryUtil'
import {
  ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID,
  useGetRecommendedProducts,
} from '@components/AlgoliaRecommendations/AlgoliaRecommendations'

import { Button } from '@components/UI-CSS/Button'
import useBreakpoints from '@hooks/useBreakpoints'
import { ALGOLIA_RECOMMENDATION_MODELS } from '@components/AlgoliaRecommendations/constants'
import { waitForLazyElementToLoad, scrollPageToTop } from '@utils/page'
import { orderApi } from '@features/order/query'
import { useLensAvailability } from '@features/rox/useLensAvailability'
import { AddToCartCta } from './AddToCartCta'
import { AddLensesCta } from './AddLensesCta'

export interface CtaProps {
  'data-element-id'?: string
  error?: boolean
  isEditing?: boolean
  isLoading?: boolean
  isStickyBar?: boolean
  isMobileSticky?: true
  product: IProduct
  roxable?: boolean
  soldOutStatus: ProductSoldOutStatus
  variant?: 'primary' | 'secondary' | 'tertiary'
  addToCartFillType?: 'outline' | 'fill'
  hideNeedPrescriptionText?: boolean
  isSelectLensesBusy?: boolean
  setSelectLensesBusy?: React.Dispatch<boolean>
  addToCart?(): void
  onClickCta?: (callback?: () => void) => void
}

const CtaPdp = forwardRef<HTMLDivElement, CtaProps>((props, ref) => {
  const {
    'data-element-id': dataElementId = 'X_X_Prod_AddCart',
    error,
    // isEditing = false,
    isLoading = false,
    isStickyBar,
    isMobileSticky,
    product,
    roxable,
    soldOutStatus,
    addToCart,
    onClickCta,
    addToCartFillType,
    hideNeedPrescriptionText,
    variant,
    isSelectLensesBusy,
    setSelectLensesBusy,
  } = props
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const addContactLensesToCartError = useSelector(addContactLensesToCartErrorSelector)
  const addSubscriptionToCartError = useSelector(addSubscriptionToCartErrorSelector)
  const addCLAccessoriesToCartError = useSelector(addCLAccessoriesToCartErrorSelector)

  const hasAddToCartError =
    !!addContactLensesToCartError || !!addSubscriptionToCartError || !!addCLAccessoriesToCartError

  const { isRXEnabled } = useStoreIdentity()
  const dispatch = useDispatch()
  const isAddToCartBusy = useSelector(addToCartBusySelector)
  const isPrescriptionLensFormOpen = useSelector(prescriptionLensFormOpenSelector)
  const isLensDataLoading = useSelector(prescriptionLensDataLoadingSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const productAvailableNotificationOpen = useSelector(openDrawerProductNotificationSelector)
  const toggleProductNotificationAvailableDrawer = () =>
    dispatch(setOpenDrawerProductNotificationAvailable(!productAvailableNotificationOpen))
  const scrollTo = useScrollTo()
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const productsPartNumber: string[] = getProductsPartNumbers([product])
  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const [recommendationCarousel, setRecommendationCarousel] = useState<Element | null>(null)
  const soldOutFeatureEnabled = isSoldOutFeatureEnabled()
  const recommendationProducts = useGetRecommendedProducts(
    ALGOLIA_RECOMMENDATION_MODELS.RELATED_PRODUCTS,
    indexName,
    productsPartNumber,
    ''
  ).recommendations

  const lensAvailability = useLensAvailability(product)
  const showSelectLensesCta = !!lensAvailability?.hasAvailability
  const isApiFulfilled = !!lensAvailability?.isFulfilled

  const openPrescriptionLenses = () => {
    if (!product) return
    setSelectLensesBusy && setSelectLensesBusy(true)
    dispatch(setCurrentProductBundle({ product }))
    dispatch(setPrescriptionLensFormOpen(true))
  }

  const onBrowseSimilarItemsClick = e => {
    e.preventDefault()
    const carouselPosition = recommendationCarousel?.getBoundingClientRect().top || 0
    const carouselHeight = recommendationCarousel?.getBoundingClientRect()?.height || 1
    const positionY = carouselPosition + (isMobile ? carouselHeight : 0) || document.body.scrollHeight - carouselHeight
    scrollTo(0, positionY)
  }

  const onAddToCartClick = () => {
    const callback = () => {
      dispatch(toggleAddSubscriptionToCartError(''))
      dispatch(toggleAddContactLensesToCartError(false))
      dispatch(orderApi.util.invalidateTags(['MiniCart']))

      if (addToCartEnabled) {
        addToCart?.()
      } else if (isStickyBar) {
        scrollPageToTop('smooth')
      }
    }

    if (onClickCta && typeof onClickCta === 'function') {
      onClickCta(callback)
    } else {
      callback()
    }
  }

  const hasRecommendedProducts = !!recommendationProducts?.length
  const showRecommendationsLink = soldOutFeatureEnabled && hasRecommendedProducts
  const isCLAccessoriesOutOfStock =
    isCLAccessoriesProduct(product) &&
    (soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT || soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK)

  const getRecommendationCarousel = async () => {
    const carouselComponent = await waitForLazyElementToLoad(`[id^=${ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID}]`)
    if (carouselComponent) setRecommendationCarousel(carouselComponent)
  }

  useEffect(() => {
    getRecommendationCarousel()
  }, [])

  const GetPdpCtas = (isDisabled?: boolean): React.ReactNode => {
    const busyAddToCart = (isLoading || isAddToCartBusy) && !hasAddToCartError
    const disabledAddToCart = isDisabled || error || isPrescriptionLensFormOpen || busyAddToCart
    const disabledSelectLenses =
      isDisabled || error || isAddToCartBusy || isPrescriptionLensFormOpen || isLensDataLoading

    const showBrowseSimilarCLAccessories = isDisabled && soldOutFeatureEnabled && !isStickyBar

    if (isCLAccessoriesOutOfStock) {
      if (hasRecommendedProducts) {
        return (
          <Button
            data-element-id="bowse-similar-tems-btn"
            color="primary"
            size="big"
            onClick={onBrowseSimilarItemsClick}
          >
            {t('CtaPDP.Labels.BrowseSimilarProducts')}
          </Button>
        )
      }
      // hide add to cart button
      return null
    }

    if (roxable && isRXEnabled && isOpticalProduct(product)) {
      return (
        <>
          {(showBrowseSimilarCLAccessories && (
            <>
              <AvailabilityWarning>
                <AvailabilityTitle>{t('Discontinued.Header')}</AvailabilityTitle>
                {t(hasRecommendedProducts ? 'Discontinued.RecommendationMsg' : 'Discontinued.NoRecommendationMsg')}
              </AvailabilityWarning>
              {hasRecommendedProducts && (
                <StyledRelativeBtn variant={variant || 'tertiary'} customBg onClick={onBrowseSimilarItemsClick}>
                  {t('SoldOut.BrowseSimilarItems')}
                </StyledRelativeBtn>
              )}
            </>
          )) ||
            (roxable && showSelectLensesCta && (
              <AddLensesCta
                id={isStickyBar ? 'Select_Lenses_CTA_Sticky' : 'Select_Lenses_CTA'}
                dataTestid={isStickyBar ? 'PdpAddToCart' : 'PdpAddToCartSticky'}
                product={product}
                onClick={() => openPrescriptionLenses()}
                disabled={disabledSelectLenses}
                busy={!!isSelectLensesBusy}
                error={error}
                variant={variant}
              />
            )) || (
              <AddToCartCta
                id={'X_X_Stickybar_AddCart'}
                dataTestid={'PdpAddToCartSticky'}
                product={product}
                onClick={onAddToCartClick}
                disabled={disabledAddToCart}
                busy={busyAddToCart}
                error={error}
                variant={variant}
                addToCartFillType={addToCartFillType}
              />
            )}
        </>
      )
    } else if (roxable && !isStickyBar && isRXEnabled && !isOpticalProduct(product)) {
      return (
        <>
          {(isDisabled && soldOutFeatureEnabled && (
            <>
              <AvailabilityWarning>
                <AvailabilityTitle>{t('Discontinued.Header')}</AvailabilityTitle>
                {t(hasRecommendedProducts ? 'Discontinued.RecommendationMsg' : 'Discontinued.NoRecommendationMsg')}
              </AvailabilityWarning>
              {hasRecommendedProducts && (
                <StyledRelativeBtn variant={variant || 'tertiary'} customBg onClick={onBrowseSimilarItemsClick}>
                  {t('SoldOut.BrowseSimilarItems')}
                </StyledRelativeBtn>
              )}
            </>
          )) || (
            <>
              {roxable && showSelectLensesCta && (
                <AddLensesCta
                  id={'X_Pdp_Prod_AddLens'}
                  dataTestid={'PdpAddLenses'}
                  product={product}
                  onClick={() => openPrescriptionLenses()}
                  disabled={disabledSelectLenses}
                  busy={!!isSelectLensesBusy}
                  error={error}
                  variant={variant}
                  parentbg="light"
                />
              )}

              {!hideNeedPrescriptionText && roxable && showSelectLensesCta && (
                <NeedPrescriptionText>{t('CtaPDP.Labels.NeedPrescription')}</NeedPrescriptionText>
              )}
              <AddToCartCta
                id={dataElementId}
                dataTestid={'PdpAddToCart'}
                product={product}
                onClick={onAddToCartClick}
                disabled={disabledAddToCart || disabledSelectLenses}
                busy={busyAddToCart}
                error={error}
                addToCartFillType={addToCartFillType}
                variant={showSelectLensesCta ? 'secondary' : 'primary'}
                customBorderColor={true}
              />
            </>
          )}
        </>
      )
    } else if (isStickyBar && isRXEnabled && !isOpticalProduct(product)) {
      return (
        <>
          {roxable && showSelectLensesCta ? (
            <AddLensesCta
              id={'rxconfig-button-sticky'}
              dataTestid={'PdpAddLenses_Sticky'}
              product={product}
              onClick={() => openPrescriptionLenses()}
              disabled={disabledSelectLenses}
              busy={!!isSelectLensesBusy}
              error={error}
              variant={variant}
              fullwidth={true}
            />
          ) : (
            <AddToCartCta
              id={'X_X_Stickybar_AddCart'}
              dataTestid={'PdpAddToCart_Sticky'}
              product={product}
              onClick={onAddToCartClick}
              disabled={disabledAddToCart || disabledSelectLenses}
              busy={busyAddToCart}
              error={error}
              variant={'primary'}
              addToCartFillType={addToCartFillType}
            />
          )}
        </>
      )
    } else {
      return (
        <AddToCartCta
          id={'X_X_Stickybar_AddCart'}
          dataTestid={'PdpAddToCart_Sticky'}
          product={product}
          onClick={onAddToCartClick}
          disabled={disabledAddToCart || disabledSelectLenses}
          busy={busyAddToCart}
          error={error}
          variant={variant}
          addToCartFillType={addToCartFillType}
        />
      )
    }
  }

  if (
    isCLAccessoriesOutOfStock ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.NONE ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.INFINITE_INVENTORY
  ) {
    return (
      <PdpCtasWrapper isStickyBar={isStickyBar} className="pdp__cta-wrapper" ref={ref}>
        {(isApiFulfilled && (
          <StyledButtonsContainer>
            {GetPdpCtas(
              soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT || soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK
            )}
          </StyledButtonsContainer>
        )) || <PreLoader fill={'dark'} size={32} />}
      </PdpCtasWrapper>
    )
  }

  if (
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ||
    soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON
  ) {
    const comingSoonText1 =
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ? t('ComingSoon.Text1') : t('ComingBackSoon.Text1')
    const comingSoonText2 =
      soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ? t('ComingSoon.Text2') : t('ComingBackSoon.Text2')

    return isStickyBar ? (
      <SoldOutContainer ref={ref} isStickyBar={true}>
        <AddToCartButton onClick={toggleProductNotificationAvailableDrawer} isStickyBar={true} isOutOfStock={true}>
          <p> {t('ProductDetails.CTA.EmailMe')}</p>
        </AddToCartButton>
      </SoldOutContainer>
    ) : (
      <SoldOutContainer ref={ref} isStickyBar={false}>
        {!isMobileSticky &&
          (soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_SOON ? (
            <>
              <LineSeparator />
              <ComingSoonWarning isStickyBar={false}>{t('ComingSoon.ComingSoon')}</ComingSoonWarning>
            </>
          ) : (
            <>
              <LineSeparator />
              <AvailabilityWarning>
                <AvailabilityTitle>
                  {t(soldOutFeatureEnabled ? comingSoonText1 : 'ComingBackSoon.Sorry')}
                </AvailabilityTitle>
                {t(soldOutFeatureEnabled ? comingSoonText2 : 'ComingBackSoon.ComingBackSoonText')}
              </AvailabilityWarning>
            </>
          ))}
        <AddToCartButton onClick={toggleProductNotificationAvailableDrawer} isOutOfStock={true}>
          <p> {t('ProductDetails.CTA.EmailMe')}</p>
        </AddToCartButton>
        {showRecommendationsLink && (
          <BrowseSimilarItemsContainer>
            <p>{t('ProductDetails.Labels.Or')}</p>
            <StyledButtonAsLink onClick={onBrowseSimilarItemsClick}>
              {t('ComingBackSoon.BrowseSimilarItems')}
            </StyledButtonAsLink>
          </BrowseSimilarItemsContainer>
        )}
      </SoldOutContainer>
    )
  }

  return null
})

export default CtaPdp
