import React, { forwardRef } from 'react'
import { useTranslation } from 'next-i18next'
import { AddToCartButton, StyledRelativeBtn } from './CtaPdp.style'
import { PreLoader } from '../../../../components/UI'
import { getModelName } from '../../../../utils/productAttributes'
import { LoaderProps } from '../../../../components/UI/Loader/preloader.type'
import { IProduct } from '@typesApp/product'

export interface AddToCartCtaProps {
  id: string
  product: IProduct
  onClick: () => void
  disabled: boolean
  busy: boolean
  error: boolean | undefined
  variant?: 'primary' | 'secondary' | 'tertiary'
  addToCartFillType?: 'outline' | 'fill'
  customBg?: boolean
  customBorderColor?: boolean
  dataTestid?: string
}

export const AddToCartCta = forwardRef<HTMLDivElement, AddToCartCtaProps>((props, ref) => {
  const { id, product, disabled, addToCartFillType, busy, onClick, error, variant, customBorderColor, dataTestid } =
    props
  const { t } = useTranslation()
  const modelName = getModelName(product)
  const spinnerFill: LoaderProps['fill'] = variant !== 'secondary' ? 'light' : 'dark'
  return (
    <StyledRelativeBtn
      filltype={addToCartFillType}
      data-testid={dataTestid}
      data-element-id={id}
      data-description={`${modelName}_${product?.partNumber}`}
      disabled={disabled}
      onClick={onClick}
      fullwidth
      variant={variant}
      customBorderColor={customBorderColor}
    >
      {busy && <PreLoader fill={spinnerFill} withButton />}
      {t([error ? 'ProductDetails.Errors.AddToCartError' : 'ProductDetails.CTA.AddToBag', 'Add to bag'])}
    </StyledRelativeBtn>
  )
})
