import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '@redux/rootReducer'
import { LensAvailabilityResponse } from '../../types/rxConfigurator'
import { DOMAIN_SUFFIX_BY_STORE_ID } from '@constants/common'
import { STORES } from '@foundation/constants/site'
import config from '@configs/index'

export const lensAvailabilityApi = createApi({
  reducerPath: 'lensAvailabilityApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),

  endpoints: build => ({
    getLensAvailability: build.query<LensAvailabilityResponse, { model: string }>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const site = state.site.currentSite
        const country = site?.country
        const environemnt = config?.rxc?.rxcEnv ?? 'prod'
        const { model } = args

        const result = await fetchWithBQ({
          url: `https://assets.clearly.${DOMAIN_SUFFIX_BY_STORE_ID[site?.storeID ?? STORES['en-ca'].storeId]}/extra/image/rxcbe/${environemnt}/availability/CL/${country?.toUpperCase()}/availability_${model}.json`,
          method: 'GET',
          mode: 'cors',
        })

        return result?.data
          ? { data: result.data as LensAvailabilityResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const { useGetLensAvailabilityQuery } = lensAvailabilityApi
