export const REG_EX = {
  NUMERIC: /^[0-9]+$/,
  EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
  PHONE: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, // /^[-+() ]*[0-9][-+() 0-9]*$/,
  PRICE: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
  NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[a-zA-Z0-9 ]*$/,
}

export const STORE_ID_QUERY_PLACEHOLDER = '{storeId}'
export const HTTP_CODE_OK = '200'
export const HTTP_CODE_CONFLICT = '409'

export const DEFINING = 'Defining'
export const DESCRIPTIVE = 'Descriptive'
export const OFFER = 'Offer'
export const DISPLAY = 'Display'
export const SEARCHTERM = 'searchTerm'
export const ORIGINALSEARCHTERM = 'originalSearchTerm'
export const ORDER_BY = 'orderBy'

export const EMPTY_STRING = ''
export const STRING_TRUE = 'true'
export const STRING_FALSE = 'false'

//Address Form
export const CHECKOUT = 'checkout'
export const ADDRESS_BOOK = 'address-book'
export const ADDRESS_SHIPPING = 'Shipping'
export const ADDRESS_BILLING = 'Billing'
export const ADDRESS_SHIPPING_BILLING = 'ShippingAndBilling'
export const NEW_SHIPPINNG_ADDRESS = 'newAddress'
export const ADDRESS_LINE = 'addressLine'
export const ADDRESSLINE1 = 'addressLine1'
export const ADDRESSLINE2 = 'addressLine2'
export const ADDRESS_1 = 'address1'
export const ADDRESS_2 = 'address2'
export const PHONE1 = 'phone1'
export const BUZZER_CODE = 'buzzerCode'
export const APARTMENT_CHECK = 'apartmentCheck'

//Admin Tools
export const IBM_ASSIGNED_ROLE_DETAILS = 'IBM_Assigned_Roles_Details'
export const BUYER_ADMIN_ROLE = '-21'
export const BUYER_APPROVAL_ROLE = '-22'

export const ADDRESS_TYPE_MAP = new Map()
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING, 'AddressBook.ShippingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_BILLING, 'AddressBook.BillingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING_BILLING, 'AddressBook.ShippingBillingLabel')

//keycodes
export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
}

export const RX_CONF_SELECTOR = 'rxcApp'

export const LANGUAGE_MASTER = {
  countryCode: 'ww',
  langCode: 'en_ww',
}

export const CommerceEnvironment = {
  categorySkeleton: {
    id: '',
    type: 'Child PIM categories',
    categoryInternal: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  productSkeleton: {
    id: '',
    type: 'Product',
    productInternal: '',
    productDesc: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  suggestionSkeleton: {
    arrIndex: '',
    id: '',
    type: 'Suggestion',
    name: '',
    url: {},
  },
  errorParamsSkeleton: {
    zero: '',
    one: '',
    two: '',
  },
  defaultLang: 'en_',
  languageMap: {
    '-24': 'en_ca',
    '-25': 'fr_ca',
    '-54': 'en_nz',
    '-47': 'en_au',
    '-99': 'en_ww',
  },
  reverseLanguageMap: {
    en_ca: '-24',
    fr_ca: '-25',
    en_nz: '-54',
    en_au: '-47',
    en_ww: '-99',
  },
  dxLanguageMap: {
    '-24': 'ca',
    '-25': 'fr-ca',
    '-47': 'au',
    '-54': 'nz',
    '-99': 'en',
  },
  localeLangMap: [
    {
      countryCode: 'ca',
      langCode: 'en_ca',
    },
    {
      countryCode: 'ca',
      langCode: 'fr_ca',
    },
    {
      countryCode: 'nz',
      langCode: 'en_nz',
    },
    {
      countryCode: 'au',
      langCode: 'en_au',
    },
    {
      ...LANGUAGE_MASTER,
    },
  ],
  itemizedTaxCountryCodes: ['ca'] as string[],
  dateFormat: {
    en_ca: { format: 'MM/dd/yyyy', displayFormat: 'MM/DD/YYYY' },
    fr_ca: { format: 'MM/dd/yyyy', displayFormat: 'MM/DD/YYYY' },
    en_nz: { format: 'dd/MM/yyyy', displayFormat: 'DD/MM/YYYY' },
    en_au: { format: 'dd/MM/yyyy', displayFormat: 'DD/MM/YYYY' },
    en_ww: { format: 'MM/dd/yyyy', displayFormat: 'MM/DD/YYYY' },
  },
  directInsuranceBilling: {
    en_ca: { enabled: true },
    fr_ca: { enabled: true },
  },
}

export const LANGUAGE_CODE_MAP = {
  fr_ca: 'fr-ca',
  en_ca: 'en-ca',
  en_nz: 'en-nz',
  en_au: 'en-au',
  en_ww: 'en-ww',
}

export const ALLOWED_COUNTRIES_WITH_THREE_LETTER_REGION = ['en-nz', 'en-au']

export const DOMAIN_ROOT = 'clearly'

export const DOMAIN_SUFFIX = {
  'en-au': 'com.au',
  'en-ca': 'ca',
  'fr-ca': 'ca',
  'en-nz': 'co.nz',
}

export const DOMAIN_SUFFIX_BY_STORE_ID = {
  '70202': 'com.au',
  '70201': 'ca',
  '70203': 'co.nz',
}

export const COUNTRY_MAP = {
  CANADA: 'ca',
  AUSTRALIA: 'au',
  NEW_ZEALAND: 'nz',
}

export const CANADA_COUNTRY_CODE = 'ca'

export const DEFAULT_COUNTRY_CODE = 'ca'
export const DEFAULT_LANG_CODE = 'en'
export const DEFAULT_BASE_URL = 'www.clearly.ca'

export const INVENTORY = {
  NON_ATP: -2,
}

export const SUCCESS_MSG_PREFIX = 'success-message.'
export const DEFAULT_LANG_ID = '-1'

// Shipping
export const IS_PERSONAL_ADDRESS_ALLOWED = 'x_isPersonalAddressesAllowedForShipping'
export const ORG_ADDRESS_DETAILS = 'orgAddressDetails'
export const ORG_ADDRESS = 'orgAddress'
export const ORDER_ID = 'orderid'
export const HYPHEN = '-'
export const UNDERSCORE = '_'
export const SPACE = ' '
export const EXCEPTION = '-exception'

// Discover
export const DISCOVER_FEATURE = 'Discover'
//Image
export const IMAGE = 'IMAGE'
export const VIDEO = 'VIDEO'
export const ATTACHMENTS = 'Attachments'

//DX
export const DX_IMAGE_PATH_STARTS_WITH = '/dx/'
export const DX_IMAGE_THUMBNAIL_TRUE = '?thumbnail=true'
//Teaser
export const DASH_BUTTON_TEASER_VIEWTYPE = 'PLP_dash_button'
export const FULL_WIDTH_BANNER_VIEWTYPE = 'full-width-banner'
export const PLP_BOX_TEASER_VIEWTYPE = 'plp-box'

//Filters
export const GROUPING_PROFILE_NAME = 'LX_findProductsByCategory_Grouping'
export const GROUPING_DISABLED_PROFILE_NAME = 'LX_findProductsByCategory_With_Attachments'
export const SEARCH_TERM_GROUPING_PROFILE_NAME = 'LX_findProductsBySearchTerm_Grouping'
export const SEARCH_TERM_PROFILE_NAME = 'LX_findProductsBySearchTerm'
export const SUGGESTIONS_KEYWORDS_PROFILE_NAME = 'LX_findNavigationSuggestion_Keywords'
export const BREADCRUMB_PROFILE = 'HCL_BreadCrumbByCategoryUniqueId'
export const CLUSTERING_PROFILE = 'LX_findProductByPartNumber_Details_Grouping'
export const ITEM_DETAILS_PROFILE_NAME = 'LX_findItemByIds_Details'
export const PROFILE_NAME_PARAM = 'profileName'
export const SORTING_BY_PRICE_LOW = '3'
export const SORTING_BY_PRICE_HIGH = '4'
export const SORTING_BY_NEW_ARRIVALS = '5'
export const SORTING_NOT_CLUSTERED = [SORTING_BY_PRICE_LOW, SORTING_BY_PRICE_HIGH]
export const USER_SEGMENT_COOKIE_NAME = 'userg'
export const USER_SEGMENT_COOKIE_VALUES_SEPARATOR = '|'
export const USER_SEGMENT_LOGGED = 'Logged'
export const USER_SEGMENT_GUEST = 'Guest'
export const USER_SEGMENT_EARLY_ACCESS = 'EarlyAccess'
export const USER_SEGMENT_REGISTERED_USERS = 'RegisteredUsers'

export const MONTH_MAP = new Map<string, string>([
  ['JAN', '01'],
  ['FEB', '02'],
  ['MAR', '03'],
  ['APR', '04'],
  ['MAY', '05'],
  ['JUN', '06'],
  ['JUL', '07'],
  ['AUG', '08'],
  ['SEP', '09'],
  ['OCT', '10'],
  ['NOV', '11'],
  ['DEC', '12'],
])

export const VM_AVAILABILITY_SERVER = 'vmcore.luxottica.com'

export const NEWSLETTER_DUPLICATE_EMAIL = '_NEWSLETTER_EMAIL_DUPLICATE'

export const BRAND_RESTRICTED_MODEL = 'RAY-BAN STORIES'
export const CHECKOUT_STEPS = {
  CHECKOUT: 'checkout',
  PAYMENT: 'payment',
  SHIPPING: 'shipping',
  UPLOAD_PRESCRIPTION: 'upload-prescription',
  REORDER_SUMMARY: 'reorder-summary',
  ORDER_CONFIRMATION: 'order-confirmation',
  POST_CHECKOUT: 'post-checkout',
  REVIEW: 'review',
}

export const ADJUSTMENT_USAGE = {
  DISCOUNT: 'discount',
  SURCHARGE: 'surcharge',
}
export const HANDLING_FEES_ADJUSTMENT = 'HANDLING_FEES'
export const HANDLING_FEES_DISCOUNT = 'HANDLING_FEES_OFF'

//from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPE = {
  pdf: 'data:application/pdf;base64,',
  png: 'data:image/png;base64,',
  jpg: 'data:image/jpeg;base64,',
  jpeg: 'data:image/jpeg;base64,',
}

export const WISHLIST = {
  LOCATION_ONLINE: 'online',
  DEFAULT_QUANTITY: '1',
}

export const ANALYTICS_PRODUCT_CATEGORY_MAP = {
  'CONTACT-LENSES': 'CONTACT LENSES',
  OPTICAL: 'OPTICS',
  'CONTACT LENSES ACCESSORIES': 'ACCESSORIES',
  FRAMES: 'EYEWEAR',
}

export const PRODUCT_IMAGE_MAX_WIDTH_DESKTOP = 400
export const PRODUCT_IMAGE_MAX_WIDTH_U426 = 213
export const PRODUCT_IMAGE_MAX_WIDTH_MOBILE = 296
export const PRODUCT_IMAGE_MAX_WIDTH_TABLET = 400
export const PRODUCT_IMAGE_MAX_WIDTH_DEFAULT = 260

export const CONTACT_LENS_DIAMETER_COLUMN = 'x_diameter'
export const CONTACT_LENS_BASE_CURVE_COLUMN = 'x_baseCurve'
export const CONTACT_LENS_SPHERE_POWER_COLUMN = 'x_spherePower'
export const CONTACT_LENS_DEFAULT_QUANTITY = '0'
export const CONTACT_LENS_ACCESSORIES_DEFAULT_QUANTITY = '1'
export const ADJUSTMENT_DISCOUNT = 'Discount'
export const SHIPPING_ADJUSTMENT_TEXT = 'Shipping Adjustment'
export const SURCHARGE_TEXT = 'Surcharge'
export const XXXXXXXXX_TEXT = 'XXXXXXXXX'
export const CONTACT_LENS_MIN_QUANTITY = 0
export const CONTACT_LENS_MAX_QUANTITY = 30

export const ORDER_ITEM_SUBSCRIPTION_ATTRIBUTE = 'RecurringFrequency'

export const CURRENCY_SYMBOLS = {
  DEFAULT: '$',
  EUR: '€',
  GBP: '£',
  USD: '$',
  CAD: '$',
  AUD: '$',
  NZD: '$',
}

export const PRESCRIPTION_FLOW_MAP = {
  MANUAL: 'MANUAL',
  ACCOUNT: 'ACCOUNT',
  UPLOAD: 'UPLOAD',
}

export const PLP_PRODUCT_GRID_ID = 'plp-product-grid'

export const PROMO_URL_PARAM = 'promo'
export const EARLY_ACCESS_PROMO = 'EarlyAccess'
export const NEWSLETTER_DRAWER_DEFAULT_ID = 'newsletter-subscribe'
export const NEWSLETTER_DRAWER_PROMO_ANZ_ID = 'newsletter-promo-subscribe-anz'
export const NEWSLETTER_DRAWER_PROMO_CA_ID = 'newsletter-promo-subscribe-ca'

export const RTK_QUERY_STATUS = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
}
