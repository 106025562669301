import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { RootReducerState } from '@redux/rootReducer'
import { LensDataResponse } from '../../types/rxConfigurator'
import { getCustomerSegmentsUtil } from '@utils/Cookies'
import config from '@configs/config.base'
import { USER_SEGMENT_GUEST } from '@constants/common'

export const roxApi = createApi({
  reducerPath: 'roxApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/store/{storeId}',
  }),
  tagTypes: ['LensData'],

  endpoints: build => ({
    roxLensData: build.query<LensDataResponse, { productId: string; langId: string }>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID
        const { langId, productId } = args
        const segment = getCustomerSegmentsUtil()[0]
        const customerSegment = (segment !== config?.algolia?.guestSegment && segment) || undefined
        const prefix = !!config.rxc.isDataLightEnabled ? '/rox/lensesDataLight/' : '/rox/lensesData/'
        const result = await fetchWithBQ({
          url: `${prefix}${productId}`,
          method: 'get',
          queryParams: {
            storeId,
            langId,
            userType: customerSegment ?? USER_SEGMENT_GUEST,
          },
        })

        return result?.data ? { data: result.data as LensDataResponse } : { error: result.error as FetchBaseQueryError }
      },
      providesTags: [{ type: 'LensData' }],
    }),
    addRxConfigurationItem: build.mutation({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID

        const result = await fetchWithBQ({
          url: '/cart/addRXConfigurationItem',
          method: 'POST',
          body: args.body,
          pathParams: { storeId: storeId },
        })
        return result?.data ?? result.error
      },
    }),
  }),
})

export const { useRoxLensDataQuery, useAddRxConfigurationItemMutation } = roxApi
