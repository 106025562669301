import React from 'react'
import { usePathname } from 'next/navigation'
import {
  StyledNewsletterBody,
  StyledNewsletterContainer,
  StyledNewsletterTypographyTitle,
  ThankYouContainer,
  StyledNewsletterTypographySubTitle,
} from './NewsLetterSubscribe.style'
import { useNewsletterSubscriptionSuccessForLandingPage } from '@foundation/hooks/useNewsletterSubscriptionSuccess'

export const SuccessContainer: React.FC = () => {
  const pathName = usePathname()
  const { signUpSuccessTitle, signUpSuccessSubtitle } = useNewsletterSubscriptionSuccessForLandingPage(pathName)

  return (
    <StyledNewsletterContainer>
      <div>
        <StyledNewsletterBody>
          <ThankYouContainer>
            <StyledNewsletterTypographyTitle>{signUpSuccessTitle}</StyledNewsletterTypographyTitle>
            <StyledNewsletterTypographySubTitle dangerouslySetInnerHTML={{ __html: signUpSuccessSubtitle }} />
          </ThankYouContainer>
        </StyledNewsletterBody>
      </div>
    </StyledNewsletterContainer>
  )
}
