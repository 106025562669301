import styled from '@mui/material/styles/styled'
//SWIPER
import { Swiper } from 'swiper/react'
import { boardsMarginConfig } from '../../../utils/boardsWithFields'

export const BoardWithFieldsContainer = styled('div', {
  name: ' BoardWithFields',
  slot: 'Container',
  shouldForwardProp: prop => prop !== 'isWithCarousel',
})<{
  isWithCarousel?: boolean
}>(({ theme, isWithCarousel }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(8),
  ...boardsMarginConfig(isWithCarousel, theme),
  img: {
    [theme.breakpoints.down(501)]: {
      height: theme.spacing(90.5),
    },
  },
  [theme.breakpoints.down(769)]: {
    position: 'relative',
    flexDirection: 'column',
  },
}))

export const BoardWithFieldsItem = styled('div', {
  name: 'BoardWithFields',
  slot: 'item',
  shouldForwardProp: prop => prop !== 'isWithCarousel',
})<{
  isWithCarousel?: boolean
}>(({ theme, isWithCarousel }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& > div': { height: '100%' },
  'div:has(> article)': {
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
    flex: '1',
    '&:first-of-type': {
      marginLeft: 0,
    },
    img: {
      verticalAlign: 'bottom',
    },
  },
  [theme.breakpoints.up(769)]: {
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down(769)]: {
    gap: isWithCarousel ? theme.spacing(8) : theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    gap: isWithCarousel ? theme.spacing(2) : theme.spacing(4),
  },
  article: {
    a: {
      width: 'auto',
    },
    [theme.breakpoints.down(769)]: {
      'a, button ': {
        width: '100%',
        maxWidth: theme.spacing(72),
      },
    },
  },
}))

export const BoardWithFieldsSlider = styled(Swiper, {
  name: 'BoardWithFields',
  slot: 'Slider',
  shouldForwardProp: prop => prop !== 'watchOverflowLastItem',
})<{
  watchOverflowLastItem?: boolean
}>(({ theme, watchOverflowLastItem }) => ({
  maxWidth: '100vw',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.swiper-wrapper': {
    minWidth: '100%',
    paddingLeft: watchOverflowLastItem ? 0 : theme.spacing(16),
    marginLeft: !watchOverflowLastItem ? 0 : `-${theme.spacing(16)}`,
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      paddingLeft: watchOverflowLastItem ? 0 : theme.spacing(8),
      marginLeft: !watchOverflowLastItem ? 0 : `-${theme.spacing(8)}`,
    },
    [theme.breakpoints.down(321)]: {
      paddingLeft: watchOverflowLastItem ? 0 : theme.spacing(4),
      marginLeft: !watchOverflowLastItem ? 0 : `-${theme.spacing(4)}`,
    },
  },
  '.swiper-slide': {
    width: '90%',
    [theme.breakpoints.up(1280)]: {
      width: '100%',
      maxWidth: 'calc(100% / 3.75)',
    },
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      maxWidth: 'calc(100% / 3.34)',
    },
    [theme.breakpoints.down(1024)]: {
      width: '100%',
      maxWidth: 'calc(100% / 2.6)',
    },
    [theme.breakpoints.down(769)]: {
      width: '83.7vw',
    },
    [theme.breakpoints.down(325)]: {
      width: '85%',
    },
  },
  '.swiper-button-disabled': {
    display: 'none',
  },
  '.swiper-button-next, .swiper-button-prev': {
    width: theme.spacing(8),
    height: theme.spacing(24),
    backgroundColor: theme.palette.background.dark.primary,
    top: '22%',
    [theme.breakpoints.down(769)]: {
      top: '28%',
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: '50%',
      // To avoid fat finger presses on mobile with the underlying component this button is made bigger invisibly
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
        bottom: '-8px',
        left: '-8px',
        borderRadius: '50%',
        border: `${theme.spacing(3)} solid transparent`,
      },
    },
    '&::after': {
      fontSize: theme.spacing(4),
      fontWeight: 800,
      color: theme.palette.text.light.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.custom.light1.grey,
    },
  },
  '.swiper-button-next': {
    right: 0,
    [theme.breakpoints.down(769)]: {
      marginRight: theme.spacing(5),
    },
  },
  '.swiper-button-prev': {
    left: 0,
    [theme.breakpoints.down(769)]: {
      marginLeft: theme.spacing(5),
    },
  },
  '.swiper-pagination': {
    bottom: '36px !important',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
    width: '100%',
    position: 'absolute',
    zIndex: 0,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  '.swiper-pagination-bullet': {
    borderRadius: 0,
    width: theme.spacing(11),
    height: 2,
    opacity: 1,
    marginLeft: '6px',
    background: theme.palette.background.light.tertiary,
    marginBottom: '-40px !important',
    '&.swiper-pagination-bullet-active': {
      background: theme.palette.background.dark.primary,
    },
    [theme.breakpoints.down(769)]: {
      width: theme.spacing(9),
    },
  },
}))
