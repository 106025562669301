import { useGetPageStaticLandingQuery } from '@features/cms/query'
import { ILXTeaser, ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { IPictureMedia, isPictureMedia } from '@typesApp/teaser'

import {
  NEWSLETTER_PRIVACY_PLACEMENT,
  NEWSLETTER_SUBSCRIBE_PAGE_LANDING_EXTRA_ITEMS,
  NEWSLETTER_SUCCESS_CTAS,
  NEWSLETTER_SUCCESS_DESCRIPTION,
  NEWSLETTER_SUCCESS_PROMOCODE,
  SUBSCRIPTION_LANDING_PAGE_DEFAULT_ID,
} from '@constants/newsletter'
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import {
  DEFAULT_COUNTRY_CODE,
  NEWSLETTER_DRAWER_DEFAULT_ID,
  NEWSLETTER_DRAWER_PROMO_ANZ_ID,
  NEWSLETTER_DRAWER_PROMO_CA_ID,
  PROMO_URL_PARAM,
} from '@constants/common'
import { isEarlyAccesFeatureEnabled, isEarlyAccessFeatureNewsLetterEnabled } from '@utils/common'
import { useSearchParams } from 'next/navigation'
import { getSite } from './useSite'

export interface INewsletterSubscriptionSuccess {
  title: string
  signUpSuccessTitle: string
  signUpSuccessSubtitle: string
  promoCodeFields?: IPromoCodeField[]
  signUpSuccessMedia?: IPictureMedia | undefined
  teaserLXCallToActionSettings?: ITeaserCallToAction[]
  landingPageSuccessMedia?: ILXTeaser[]
  privacyText?: string
}

export interface IPromoCodeField {
  code: string
  description: string
}

export const useNewsletterSubscriptionSuccess = (): INewsletterSubscriptionSuccess => {
  const mySite = getSite()
  const isEarlyAccessEnabled = isEarlyAccesFeatureEnabled()
  const isEANewsLetterEnabled = isEarlyAccessFeatureNewsLetterEnabled()
  const searchParams = useSearchParams()
  const promo = searchParams.get(PROMO_URL_PARAM)
  const isPromo = isEarlyAccessEnabled && (!!promo || isEANewsLetterEnabled)
  const promoSubscribePageId =
    mySite?.country !== DEFAULT_COUNTRY_CODE ? NEWSLETTER_DRAWER_PROMO_ANZ_ID : NEWSLETTER_DRAWER_PROMO_CA_ID

  const params = {
    pageId: (isPromo ? promoSubscribePageId : NEWSLETTER_DRAWER_DEFAULT_ID) + '/thank-you',
  }
  const { data } = useGetPageStaticLandingQuery(params, { skip: false })
  const contentPlacements = data?.contentPlacements

  const getContentPlacement = (placementName: string) =>
    contentPlacements?.filter(placement => placement.name === placementName)[0] ?? null

  const title = data?.title ?? ''

  const descriptionItem = getContentPlacement(NEWSLETTER_SUCCESS_DESCRIPTION)?.items[0] as ILXTeaser
  const signUpSuccessTitle = descriptionItem?.teaserTitle4 ?? ''
  const signUpSuccessSubtitle = descriptionItem?.teaserText2 ?? ''

  const promoCodeItems = getContentPlacement(NEWSLETTER_SUCCESS_PROMOCODE)?.items as ILXTeaser[]
  const promoCodeFields: IPromoCodeField[] = []
  promoCodeItems?.map(item => {
    const promoCodeCtaSetting = item?.teaserLXCallToActionSettings[0]?.target as unknown as ICMPlaceholder
    const code = promoCodeCtaSetting?.title
    promoCodeFields.push({
      code,
      description: item.teaserText2 ?? item.title,
    })
  })

  const mediaItem = getContentPlacement(NEWSLETTER_SUCCESS_CTAS)?.items[0] as ILXTeaser
  const media = mediaItem?.media?.find(isPictureMedia)
  const teaserLXCallToActionSettings = mediaItem?.teaserLXCallToActionSettings ?? []

  const privacyTextItem = getContentPlacement(NEWSLETTER_PRIVACY_PLACEMENT)?.items[0] as ILXTeaser
  const privacyText = privacyTextItem?.teaserText2 ?? ''

  const landingPageSuccessMedia = getContentPlacement(NEWSLETTER_SUBSCRIBE_PAGE_LANDING_EXTRA_ITEMS)
    ?.items as ILXTeaser[]

  return {
    title,
    signUpSuccessTitle,
    signUpSuccessSubtitle,
    promoCodeFields,
    signUpSuccessMedia: media,
    teaserLXCallToActionSettings,
    landingPageSuccessMedia,
    privacyText,
  }
}

export const useNewsletterSubscriptionSuccessForLandingPage = (pathName?: string): INewsletterSubscriptionSuccess => {
  const parentPage = pathName ? pathName.substring(pathName.lastIndexOf('/') + 1) : SUBSCRIPTION_LANDING_PAGE_DEFAULT_ID
  const params = {
    pageId: parentPage + '/thank-you',
  }

  const { data } = useGetPageStaticLandingQuery(params, { skip: false })
  const contentPlacements = data?.contentPlacements
  const getContentPlacement = (placementName: string) =>
    contentPlacements?.filter(placement => placement.name === placementName)[0] ?? null

  const title = data?.title ?? ''

  const descriptionItem = getContentPlacement(NEWSLETTER_SUCCESS_DESCRIPTION)?.items[0] as ILXTeaser
  const signUpSuccessTitle = descriptionItem?.teaserTitle4 ?? ''
  const signUpSuccessSubtitle = descriptionItem?.teaserText2 ?? ''

  return {
    title,
    signUpSuccessTitle,
    signUpSuccessSubtitle,
  }
}
