import {
  ADDRESS_BILLING,
  ADDRESS_SHIPPING,
  ADDRESS_SHIPPING_BILLING,
  EMPTY_STRING,
  EXCEPTION,
} from '../../../constants/common'
import CheckoutAddress from './components/CheckoutAddress'
import { CheckoutPageType } from './components/CheckoutAddress/CheckoutAddress'
import { hasCountryNewsletterCheck } from '../../../constants/checkout'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { billingFormFieldsSelector, shippingFormFieldsSelector } from '../../../redux/selectors/site'
import { useDispatch, useSelector } from 'react-redux'
import { AddressFormData } from '../../../types/form'
import AddressSelection from './components/AddressSelection'
import { CheckoutAddressFormField, UsableAddress } from '../../../types/checkout'
import ShippingFooter from './components/ShippingFooter/ShippingFooter'
import { ShippingBillingCheckbox, ShippingBillingTitle, ShippingWrapper } from './Shipping.style'
import {
  cartProductCountSelector,
  cartSelector,
  isRXProductsLimitExceededSelector,
  orderItemsSelector,
  shipInfosSelector,
  subscriptionItemsCountSelector,
} from '../../../features/order/selector'
import { useShipping } from './useShipping'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { loginStatusSelector, userDetailsSelector } from '../../../redux/selectors/user'
import { toggleShippingAsBilling } from '../../../features/order/slice'
import { useCheckoutForm } from '../../../hooks/useCheckoutForm'
import { WrapperCheckoutAddressSelection } from './components/CheckoutAddress/CheckoutAdress.style'
import { FormControlLabel } from '@mui/material'
import addressUtil from '../../../utils/addressUtil'
import {
  getExceptionShipMethod,
  getNonExceptionShipMethod,
  isExceptionalAddress,
  getStandardShipMethod,
  isAddressEligibleForMoreThanStandard,
  getPayload,
} from '../../../utils/order'
import { Contact, PasswordFormData, PersonResponse } from '../../../types/user'
import { updateShipMode } from '../../../features/order/thunks'
import { ShippingMethodsEnum } from '../../../types/cart'
import { orderApi, useLazyGetShippingInfoQuery } from '../../../features/order/query'
import personService from '../../../foundation/apis/transaction/person.service'
import { CheckoutContext } from '../Checkout'
import { DedicatedShippingMode } from '@typesApp/order'
import { CreatePassword } from './components/CreatePassword/CreatePassword'
import { FormProvider, useForm } from 'react-hook-form'
import { useYupValidationResolver } from '@utils/validationResolver'
import { buildYupValidationSchema } from '@constants/form'
import { PASSWORD_FORM_FIELDS } from '@constants/user'
import { isEmpty } from 'lodash-es'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { getSubscribedItems } from '@views/Subscription/helpers/subscriptionHelpers'
import { useRouter } from 'next/router'
import { CART } from '@constants/routes'
import { setIsNewsletterSubscriptionRequested } from '@features/checkout/slice'
import { isNewsletterSubscriptionRequestedSelector } from '@features/checkout/selector'

/**
 * Shipping and billing section
 * displays shipping and billing input/selection
 */
const Shipping: FC = () => {
  const {
    usableShipAddresses,
    selectedShipAddressIds,
    submit,
    newShippingAddress,
    addressId,
    setAddressId,
    updateAddress,
    handleAddressChange,
    isShippingUsedAsBilling,
    billingAddressData,
    shippingAddressData,
    setBillingAddressData,
    setShippingAddressData,
    userHasSavedAddresses,
    shippingLoading,
    addressChanging,
    setSelectedShipModeIds,
  } = useShipping()

  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { country } = useStoreIdentity()
  const { setShippingZipCode } = useContext(CheckoutContext)
  const [isEditingAddress, setIsEditingAddress] = useState(false)
  const [isAddressNotEligible, setIsAddressNotEligible] = useState(false)
  const [originalShipModeCode, setOriginalShipModeCode] = useState('')
  const [originalShipModeId, setOriginalShipModeId] = useState('')
  const [isValidAddressSelected, setValidAddressSelected] = useState<boolean>(newShippingAddress)
  const userDetails = useSelector(userDetailsSelector)
  const isNewsletterSubscriptionRequested = useSelector(isNewsletterSubscriptionRequestedSelector)
  const [getShippingInfo] = useLazyGetShippingInfoQuery()
  const shippingFormFields = useSelector(shippingFormFieldsSelector) || []
  const billingFormFields = useSelector(billingFormFieldsSelector) || []
  const isRXProductsLimitExceeded = useSelector(isRXProductsLimitExceededSelector)
  const subscriptionItemCount = useSelector(subscriptionItemsCountSelector)
  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const userEmail = userDetails?.email1 || ''
  const isLoggedIn = useSelector(loginStatusSelector)
  const { firstName, lastName } = userDetails || {
    firstName: '',
    lastName: '',
  }

  const shippingAddressFormDataInit = mapFormConfs(shippingFormFields)
  const billingAddressFormDataInit = mapFormConfs(billingFormFields)

  const isStoreNewsletterSubscriptionRequired = useMemo<boolean>(
    () => hasCountryNewsletterCheck(mySite?.xStoreCfg?.optInBoxes),
    [mySite?.xStoreCfg?.optInBoxes]
  )

  const countryDefaultValue = (shippingAddressData?.country || country).toUpperCase()

  const selectedAddress = userDetails?.contact?.find(address => address.addressId === addressId)

  const selectedShippingAddress = {
    ...selectedAddress,
    addressLine1: selectedAddress?.addressLine?.[0] || '',
    ...addressUtil.getAddressWithDelimiter(selectedAddress?.addressLine?.[1]),
    country: countryDefaultValue,
  }

  const defaultShippingAddress = {
    ...shippingAddressFormDataInit,
    email1: userEmail || '',
    addressLine2: '',
    buzzerCode: '',
    apartmentCheck: false,
    firstName: firstName || '',
    lastName: lastName || '',
    country: countryDefaultValue,
    isApartmentRuralOrPO: false,
  }

  const defaultPasswordFormValues: PasswordFormData = {
    password1: '',
    password2: '',
  }

  const orderItems = useSelector(orderItemsSelector)
  const cartItemsCount = useSelector(cartProductCountSelector)
  const hasSubscribedItems = !!getSubscribedItems(orderItems).length
  //TODO we will remove this check  on phase 2 when we implement multiple items with subscriptions
  const hasSubscriptionMultipleItemsError = cartItemsCount > 1 && hasSubscribedItems

  const order = useSelector(cartSelector)
  const shippingName: string = orderItems[0].shipModeDescription
  const shippingModeCode: string = orderItems[0].shipModeCode
  if (originalShipModeCode === '') {
    setOriginalShipModeCode(orderItems[0].shipModeCode)
    setOriginalShipModeId(orderItems[0].shipModeId)
  }
  const shipInfos = useSelector(shipInfosSelector)

  const shippingForm = useCheckoutForm({
    defaultValues: defaultShippingAddress,
    fields: shippingFormFields,
  })

  const billingForm = useCheckoutForm({
    defaultValues: {
      ...billingAddressFormDataInit,
      ...billingAddressData,
      country: countryDefaultValue,
    },
    fields: billingFormFields,
  })

  const passwordFormMethods = useForm<PasswordFormData>({
    defaultValues: defaultPasswordFormValues,
    mode: 'onTouched',
    resolver: useYupValidationResolver(
      buildYupValidationSchema({
        formFields: PASSWORD_FORM_FIELDS,
        i18nInvalidMsgBase: 'RegistrationLayout.Msgs.',
        i18nFormFieldsInvalidMsgs: {},
        t,
      })
    ),
  })

  const selectedAddressId = addressId ?? selectedShipAddressIds[0]
  const contactArray = useSelector(userDetailsSelector)?.contact || []
  const contactMap = personService.generateContactMap(contactArray)

  useEffect(() => {
    selectedAddressId &&
      contactMap &&
      contactMap[selectedAddressId] &&
      setShippingZipCode(contactMap[selectedAddressId].zipCode)
  }, [contactMap])

  const shouldSubscribeToNewsletter =
    !userDetails?.x_data.hasNewsletter &&
    (!isStoreNewsletterSubscriptionRequired ||
      (isStoreNewsletterSubscriptionRequired && isNewsletterSubscriptionRequested))

  useEffect(() => {
    shippingForm.reset(isEditingAddress ? selectedShippingAddress : defaultShippingAddress)
  }, [isEditingAddress])

  useEffect(() => {
    dispatch(setIsNewsletterSubscriptionRequested(!!userDetails?.x_data.hasNewsletter))
  }, [dispatch, userDetails?.x_data.hasNewsletter])

  useEffect(() => {
    dispatch(toggleShippingAsBilling(true))
  }, [])

  const setExceptionalShipCodeForSAP = (
    shippingName: string,
    shippingAddressData?: AddressFormData,
    userDetails?: PersonResponse,
    shipModes?: DedicatedShippingMode[]
  ) => {
    /*
      Send shipping exception code to SAP if address is apartment and there is no buzzer code.
  
      Shipping exception is sent to BE (which in turn sends to SAP) by
      setting ship mode to selected shipping method's exception mode.
      For example, if user selects "Standard", set ship mode as "Standard-exception",
      for "Express", set as "Express-exception" and so on.
      These additional ship modes are also provided by "/usable_shipping_info" API.
    */

    if (isExceptionalAddress(selectedAddressId, shippingAddressData, userDetails)) {
      if (!shippingName.endsWith(EXCEPTION)) {
        const shipModeId = getExceptionShipMethod(shippingName, shipModes)?.shipModeId
        shipModeId && dispatch(updateShipMode({ shipModeId }))
        shipModeId && setSelectedShipModeIds([shipModeId])
      }
    } else {
      if (shippingName.endsWith(EXCEPTION)) {
        const shipModeId = getNonExceptionShipMethod(shippingName, shipModes)?.shipModeId
        shipModeId && dispatch(updateShipMode({ shipModeId }))
        shipModeId && setSelectedShipModeIds([shipModeId])
      }
    }
  }

  const checkShippingEligibility = (zipCode?: string) => {
    const isExpressEligible =
      originalShipModeCode === ShippingMethodsEnum.Standard || isAddressEligibleForMoreThanStandard(zipCode)
    setIsAddressNotEligible(!isExpressEligible)
    if (originalShipModeCode !== ShippingMethodsEnum.Standard && !isExpressEligible) {
      const standardShipModeId = getStandardShipMethod(shippingName, shipInfos)?.shipModeId
      standardShipModeId && dispatch(updateShipMode({ shipModeId: standardShipModeId }))
      standardShipModeId && setSelectedShipModeIds([standardShipModeId])
    } else {
      dispatch(updateShipMode({ shipModeId: originalShipModeId }))
      setSelectedShipModeIds([originalShipModeId])
    }
    dispatch(
      orderApi.endpoints.getCart.initiate({
        ...getPayload(mySite, Shipping),
        storeId: mySite.storeID,
        fetchCatentries: false,
        fetchShippingInfo: false,
        refetch: true,
        sessionId: Date.now(),
      })
    )
  }

  const isSelectedAddressValid = (
    address: UsableAddress,
    addressDetail: Contact,
    isAddressInvalid: boolean | undefined
  ) => {
    return (
      (selectedAddressId ? selectedAddressId === address.addressId : addressDetail?.primary === 'true') &&
      !isAddressInvalid
    )
  }

  useEffect(() => {
    usableShipAddresses.map((address: UsableAddress) => {
      const addressDetail = contactMap[address.addressId] || ({} as typeof contactMap)

      if (!selectedAddressId && addressDetail?.primary === 'true') {
        setShippingZipCode?.(contactMap[addressDetail.addressId].zipCode || '')
        setAddressId(addressDetail.addressId)
      }
      const { firstName, lastName, addressLine, city, state, zipCode, country } = addressDetail
      const addressDataFields = [
        [firstName || '', lastName || ''].join(' '),
        addressLine ? addressLine[0].replace(/\s/g, ' ') || ' ' : '',
        city || '',
        state || '',
        country || '',
        zipCode || '',
      ]
      const customNickName = addressDetail
        ? addressUtil.getAddressNickName(addressDataFields.slice(1))
        : address.nickName
      address.nickName = customNickName
      const isAddressInvalid = shippingFormFields?.some(field => {
        const fieldName = field.fieldName === 'addressLine1' ? 'addressLine' : field.fieldName
        const fieldValue = field.fieldName === 'addressLine1' ? addressDetail[fieldName][0] : addressDetail[fieldName]
        if (field.mandatory) {
          if (!fieldValue) {
            return true
          }
          return !new RegExp(field.validation || '^$').test(fieldValue)
        }
        return false
      })
      const isValidAddress = isSelectedAddressValid(address, addressDetail, isAddressInvalid)
      isValidAddress && setValidAddressSelected(isValidAddress)
    })
  }, [])

  useEffect(() => {
    if (order?.orderItem) {
      getShippingInfo({
        orderId: order.orderId,
      })
    }
  }, [order])

  useEffect(() => {
    if (hasSubscriptionMultipleItemsError) {
      router.push(`/${langCode}/${CART}`)
    }
  }, [hasSubscriptionMultipleItemsError])

  return (
    <>
      <ShippingWrapper>
        {usableShipAddresses && (
          <>
            <WrapperCheckoutAddressSelection hidden={isEditingAddress || !userHasSavedAddresses}>
              <AddressSelection
                addressList={usableShipAddresses}
                selectedAddressId={selectedAddressId || EMPTY_STRING}
                handleChange={(e, zipCode: string) => {
                  handleAddressChange(e)
                  checkShippingEligibility(zipCode)
                }}
                setIsEditingAddress={setIsEditingAddress}
                setAddressId={setAddressId}
                setValidAddressSelected={setValidAddressSelected}
              />
            </WrapperCheckoutAddressSelection>
            <CheckoutAddress
              addressFormFields={shippingFormFields}
              addressType={isShippingUsedAsBilling ? ADDRESS_SHIPPING_BILLING : ADDRESS_SHIPPING}
              form={shippingForm}
              formName={`address-form-${CheckoutPageType.SHIPPING}`}
              hidden={!isEditingAddress && !newShippingAddress}
              isEditingAddress={isEditingAddress}
              setIsEditingAddress={setIsEditingAddress}
              updateAddressData={shippingAddressData}
              updateAddress={updateAddress}
              onFormDataChanged={(_type, data) => {
                data.addressLine1 && setShippingAddressData(data)
                data.zipCode && setShippingZipCode(data.zipCode)
              }}
            />
          </>
        )}
        {!isEditingAddress && (
          <>
            <FormControlLabel
              control={
                <ShippingBillingCheckbox
                  value={isShippingUsedAsBilling}
                  checked={isShippingUsedAsBilling}
                  onChange={e => dispatch(toggleShippingAsBilling(e.target.checked))}
                />
              }
              label={t('Shipping.Labels.SameAsShipping')}
              style={{ margin: 0 }}
            />
            {!isShippingUsedAsBilling && <ShippingBillingTitle>{t('Shipping.billingTitle')}</ShippingBillingTitle>}
            <CheckoutAddress
              addressFormFields={billingFormFields}
              addressType={ADDRESS_BILLING}
              form={billingForm}
              formName={`address-form-${CheckoutPageType.PAYMENT}`}
              updateAddress={updateAddress}
              setIsEditingAddress={setIsEditingAddress}
              hidden={isShippingUsedAsBilling}
              onFormDataChanged={(_type, data) => setBillingAddressData(data)}
            />
            <hr style={{ marginTop: '2rem' }} />
          </>
        )}
        {!isEditingAddress && isSubscriptionEnabled && subscriptionItemCount > 0 && !isLoggedIn && (
          <FormProvider {...passwordFormMethods}>
            <CreatePassword />
          </FormProvider>
        )}
      </ShippingWrapper>
      {!isEditingAddress && (
        <ShippingFooter
          hasNewsletterSubscriptionCheckmark={isStoreNewsletterSubscriptionRequired}
          isProceedButtonDisabled={isRXProductsLimitExceeded}
          shippingLoading={shippingLoading || addressChanging}
          isAddressNotEligible={isAddressNotEligible}
          isValidAddressSelected={isValidAddressSelected || newShippingAddress}
          onButtonClick={() => {
            setExceptionalShipCodeForSAP(shippingModeCode, shippingAddressData, userDetails!, shipInfos)
            billingForm.trigger(undefined, { shouldFocus: true })
            shippingForm.trigger(undefined, { shouldFocus: true })
            if (isSubscriptionEnabled && subscriptionItemCount > 0 && !isLoggedIn) {
              passwordFormMethods.trigger(undefined, { shouldFocus: true })
              const newPassword = passwordFormMethods.getValues()
              const { errors } = passwordFormMethods.formState
              let registrationDetails: AddressFormData | null = shippingForm.getValues()
              if (userDetails?.contact && userDetails.contact.length) {
                registrationDetails = null
              }
              if (!newPassword.password1 || !newPassword.password2) {
                return
              }
              if (isEmpty(errors)) submit(shouldSubscribeToNewsletter, registrationDetails, newPassword)
              return
            }
            submit(shouldSubscribeToNewsletter)
          }}
        />
      )}
    </>
  )
}

const mapFormConfs = (conf: CheckoutAddressFormField[]): AddressFormData => {
  const jsonObjectOfMap = {}

  conf.forEach(item => {
    jsonObjectOfMap[item.fieldName] = ''
  })

  return jsonObjectOfMap
}

export default Shipping
