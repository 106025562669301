import * as React from 'react'
import { useState } from 'react'
import { EyeContactLensAttribute, EyeContanctLensOption } from '@typesApp/product'
import {
  MultiDropDownContent,
  DropDownContainer,
  DropDownListItem,
  DropDownListItemButton,
  DropDownPopper,
  ContactLensesSelectValueField,
} from './ContactLensesSelect.style'
import { ClickAwayListener } from '@mui/material'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import styles from './styles/ContactLensesSelect.module.scss'
import clsx from 'clsx'
import HTMLReactParser from 'html-react-parser'

export interface ContactLensesSelectProps {
  id: EyeContactLensAttribute
  eye: string
  onSelectValueChange?: (eye: string, id: EyeContactLensAttribute, value?: string | null) => void
  value?: string | null
  options: EyeContanctLensOption[]
  open?: boolean
  onDropDownToggle?: (open?: boolean) => void
  error?: boolean
  disabled?: boolean
  multifield?: boolean
  listheading?: React.ReactNode
}

const getOptionByIndex = (index: number, options?: ContactLensesSelectProps['options']) => {
  return options?.find((_option, i) => i === index)?.value
}

const getOptionTextByValue = (value: string | null, options?: ContactLensesSelectProps['options']) => {
  return options?.find(option => option.value === value)?.text
}

export const ContactLensesSelect: React.FC<ContactLensesSelectProps> = ({
  id,
  eye,
  onSelectValueChange,
  onDropDownToggle,
  error,
  disabled,
  value,
  options,
  multifield,
  listheading,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<number>(0)
  const [open, setIsOpen] = useState<boolean>(false)

  const handleListKeyDown = e => {
    let selectedOptionIndex: number
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        onDropDownToggle && onDropDownToggle(false)
        break
      case 'ArrowUp':
        e.preventDefault()
        selectedOptionIndex = selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      case 'ArrowDown':
        e.preventDefault()
        selectedOptionIndex = selectedOption === options.length - 1 ? 0 : selectedOption + 1
        setSelectedOption(selectedOptionIndex)
        onSelectValueChange && onSelectValueChange(eye, id, getOptionByIndex(selectedOptionIndex, options))
        break
      default:
        break
    }
  }
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)} {...props}>
      <DropDownContainer
        disabled={disabled || false}
        error={error || false}
        open={open || false}
        onClick={() => !disabled && setIsOpen(!open)}
      >
        <ContactLensesSelectValueField
          data-testid={`${eye}_${id}`}
          type="button"
          disabled={disabled || false}
          aria-haspopup="listbox"
          aria-expanded={open}
          className={open ? 'expanded' : ''}
          onKeyDown={handleListKeyDown}
        >
          {getOptionTextByValue(value || null, options)}
        </ContactLensesSelectValueField>
        <DropDownPopper open={open}>
          {listheading && listheading}
          <MultiDropDownContent
            multifield={multifield}
            role="listbox"
            aria-activedescendant={options[selectedOption]?.text}
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
          >
            {options?.map(({ value, text, notAvailable }, index) => {
              return (
                <DropDownListItem
                  key={`${eye}_${index}_${text}`}
                  aria-selected={selectedOption === index && !notAvailable}
                  multifield={multifield}
                >
                  <DropDownListItemButton
                    notAvailable={notAvailable}
                    onClick={() => {
                      if (!disabled && !notAvailable) {
                        onSelectValueChange && onSelectValueChange(eye, id, value)
                        setIsOpen(false)
                      }
                    }}
                  >
                    {HTMLReactParser(text)}
                  </DropDownListItemButton>
                </DropDownListItem>
              )
            })}
          </MultiDropDownContent>
        </DropDownPopper>
        <SVGIcon library="arrow" name="arrow-down" className={clsx(styles.dropDownIcon, open && styles.open)} />
      </DropDownContainer>
    </ClickAwayListener>
  )
}
