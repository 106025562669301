import { useDispatch, useSelector } from 'react-redux'
import { Box, Hidden, useMediaQuery } from '@mui/material'
import {
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapEDD,
  CartRecapContentContainer,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapModelPriceDataRow,
  CartRecapDataRowProductModel,
  CartRecapBrand,
  CartRecapActionItem,
  CartRecapActions,
} from '../CartRecap.style'
import { getModelCode, getModelName } from '../../../../../utils/productAttributes'

import { OrderRecapItemProps } from '@typesApp/order'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import React from 'react'
import { catentriesSelector, orderItemsSelector } from '../../../../../features/order/selector'
import theme from '../../../../../themes'
import { useAppSelector } from '../../../../../hooks/redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { useContactLensCartItemData } from '../../../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { ContactLensQuantity } from './ContactLensQuantity'
import { formatOrderRecapItemPrices } from '../../../../../utils/order'
import { LensDetailsSection } from './ContactLensDetails.style'

import {
  doneRemovingCartItem,
  removeCartItemIdSelector,
  removingCartItemSelector,
  startRemovingCartItem,
  startRemovingCLEye,
} from '../../../../../features/cartui/cartuiSlice'
import { getInsuranceEventModule } from '../../../../../components/DirectBilling'
import { OrderItemContactLensData } from '@typesApp/cart'
import { PreLoader } from '../../../../../components/UI'
import { InsuranceEligibleCallout } from './InsuranceEligibleCallout'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { determineAlgoliaPrice, getProductPrice } from '@foundation/algolia/algoliaPrice'
import { toNumber } from 'lodash-es'
import { useRouter } from 'next/router'
import CurrencyService from '@services/CurrencyService'
import {
  getTotalAdjustments,
  isItemSubscribed,
  isSubscriptionAllowedForItem,
} from '@views/Subscription/helpers/subscriptionHelpers'
import { useSite } from '@foundation/hooks/useSite'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { OrderRecapClItemLensDetails } from '@components/order-recap/OrderRecapClItemLensDetails'
import { PRODUCT_URL_SEARCH_PARAMS } from '@constants/product'
import { useEstimatedDeliveryDate } from '@views/Checkout/Shipping/useEstimatedDeliveryDate'
import { useDeleteCLOrderItemsMutation, useLazyGetCartQuery } from '@features/order/query'
import { usePathname } from 'next/navigation'
import { isReOrderSummary } from '@utils/routeUtils'
import { ItemSubscriptionHeader } from '@views/Subscription/cart/components/ItemSubscriptionHeader'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'
import { ProductPriceAlgolia } from '@views/ProductDetails/components/ProductPriceAlgolia'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import clsx from 'clsx'
import styles from './styles/CartRecap.module.scss'

export interface ContactLensQuantityChangeOptions {
  quantity: string
  orderItemId: string
  eye: string
  contactLensData: OrderItemContactLensData
}

const CartRecapClItem: React.FC<OrderRecapItemProps> = props => {
  const { onDelete, onItemUpdated, orderItem } = props
  const { productId } = orderItem
  const { t } = useTranslation()
  const router = useRouter()
  const { mySite } = useSite()
  const dispatch = useDispatch()
  const [getCart] = useLazyGetCartQuery()
  const { basePath } = useStoreIdentity()
  const pathname = usePathname()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const catentries = useAppSelector(catentriesSelector)
  const isRemovingCartItem = useSelector(removingCartItemSelector)
  const removeOrderItemId = useSelector(removeCartItemIdSelector)
  const isRemovingCurrentItem = isRemovingCartItem && removeOrderItemId === orderItem?.orderItemId
  const [deleteCLOrderItems] = useDeleteCLOrderItemsMutation()
  const product = catentries && catentries[productId]
  const { interceptRemoveCartItem, interceptChangeQuantity } = getInsuranceEventModule()
  const { orderItemClData, setOrderItemClData, totalBoxes } = useContactLensCartItemData({
    orderItem,
  })

  const productModelName = getModelName(orderItem)
  const productBaseModelCode = getModelCode(orderItem)
  const { productOrderItemPrice, productUnitPrice } = formatOrderRecapItemPrices(orderItem)
  const orderItems = useSelector(orderItemsSelector)
  const subscriptionDiscount = getTotalAdjustments(orderItem, orderItems, true)

  const subscriptionConfig = useSelector(subscriptionConfigSelector)
  const showSubscriptionHeader =
    isSubscriptionAllowedForItem(orderItem, mySite, subscriptionConfig) && !isReOrderSummary(pathname)
  const showSubscriptionDiscountSection =
    subscriptionConfig.enabled && isItemSubscribed(orderItem) && !!subscriptionDiscount
  const customerSegments = useCustomerSegmentsUtil()

  const algoliaPrice = product?.x_price
    ? determineAlgoliaPrice(product?.x_price, customerSegments)
    : {
        listPrice: productUnitPrice || '',
        offerPrice: productUnitPrice || '',
        percentageDiscount: 0,
        startDate: '',
        endDate: '',
        precedence: 0,
        priceListPrecedence: 0,
      }

  const { initialPrice, offerPrice, discountPercentage } = getProductPrice(
    algoliaPrice,
    [orderItem.quantity],
    orderItem.adjustment
  )
  const estimatedDeliveryDate = useEstimatedDeliveryDate(orderItem, true)
  const orderItemPriceAfterAutoDiscount = offerPrice * (totalBoxes ?? 1)
  const isTotalDiscounted = toNumber(orderItemPriceAfterAutoDiscount.toFixed(2)) < toNumber(productOrderItemPrice)

  const attachments = catentries?.[productId] ? catentries[productId].attachments ?? [] : []
  const showEditLens = Boolean(orderItem.seo && orderItem.seo.href) && !isReOrderSummary(pathname)
  const editLink = Boolean(orderItem.seo && orderItem.seo.href)
    ? `${orderItem.seo.href}?${PRODUCT_URL_SEARCH_PARAMS.EDIT_CONTACT_LENS}=1`
    : ''

  const onImageClicked = () => {
    const seoHref = orderItem.seo && orderItem.seo.href ? orderItem.seo.href : ''
    const url = `${basePath}${seoHref}`
    router.push(url)
  }

  const handleClChangeQuantity = (options: ContactLensQuantityChangeOptions) => {
    interceptChangeQuantity(() => handleClChangeQuantityImpl(options))
  }

  const handleClChangeQuantityImpl = (options: ContactLensQuantityChangeOptions) => {
    const { contactLensData, eye, quantity, orderItemId } = options

    setOrderItemClData({
      ...contactLensData,
      [eye]: {
        ...contactLensData[eye],
        quantity: quantity,
      },
    })

    onItemUpdated && onItemUpdated(quantity || '', orderItemId || '')
  }

  const onEditClick = () => {
    const { interceptEditCartItem } = getInsuranceEventModule()
    interceptEditCartItem(() => {
      router.push(editLink)
    })
  }

  const handleDelete = () => {
    if (!isRemovingCurrentItem) {
      interceptRemoveCartItem(() => {
        onDelete(orderItem)
      })
    }
  }

  const onRemoveEye = (orderitemId: string, leftItemId: string | undefined, rightItemId: string | undefined) => {
    const { interceptRemoveCartItem } = getInsuranceEventModule()
    interceptRemoveCartItem(() => {
      removeSingleEye(orderitemId, leftItemId, rightItemId)
    })
  }

  const removeSingleEye = (orderitemId: string, leftItemId: string | undefined, rightItemId: string | undefined) => {
    dispatch(startRemovingCartItem(leftItemId || rightItemId || ''))
    dispatch(
      startRemovingCLEye({
        orderItemId: orderitemId || orderItem.orderItemId,
        left: !!leftItemId,
        right: !!rightItemId,
      })
    )
    deleteCLOrderItems({
      storeId: mySite.storeId,
      leftItemId,
      rightItemId,
    })
      .unwrap()
      .then(() => {
        getCart({ storeId: mySite.storeId }).finally(() => {
          dispatch(doneRemovingCartItem(!orderItem.groupedItem ? orderItem : undefined))
        })
      })
      .catch(() => {
        dispatch(doneRemovingCartItem())
      })
  }

  return (
    <Box>
      <ProductContextProvider productData={{ product }}>
        <InsuranceEligibleCallout orderItem={orderItem} />
        <CartRecapItemWrapper data-testid={'checkoutCartItem'}>
          {!!estimatedDeliveryDate && (
            <CartRecapEDD>
              {t('CartRecap.Labels.EstimatedDeliveryDate')}
              <span> &nbsp;{estimatedDeliveryDate}</span>
            </CartRecapEDD>
          )}
          {showSubscriptionHeader && <ItemSubscriptionHeader orderItem={orderItem} />}
          <CartRecapContentContainer>
            <CartRecapImageContainer>
              <Box>
                {isMobile && <ActionCTAs handleDelete={handleDelete} isRemovingCurrentItem={isRemovingCurrentItem} />}
                <ProductImage
                  attachments={attachments}
                  data-element-id="X_X_Prods_ProdLink"
                  width={CART_PRODUCT_IMAGE_WIDTH}
                  usage={'PDP'}
                  onClick={onImageClicked}
                />
              </Box>
            </CartRecapImageContainer>
            <CartRecapRightColumn>
              <CartRecapData>
                <Hidden smDown>
                  <ActionCTAs handleDelete={handleDelete} isRemovingCurrentItem={isRemovingCurrentItem} />
                </Hidden>

                <CartRecapTop>
                  <CartRecapBrand>{productModelName}</CartRecapBrand>
                </CartRecapTop>
                <CartRecapDataContent>
                  {orderItemClData && (
                    <>
                      <StyledCartRecapDivider />
                      <ContactLensQuantity
                        orderItemClData={orderItemClData}
                        listPrice={Number(initialPrice)}
                        offerPrice={Number(offerPrice)}
                        percentageDiscount={discountPercentage || 0}
                        currency={orderItem.currency}
                        onRemoveEye={onRemoveEye}
                        onQuantityChange={(quantity, orderItemId, eye) => {
                          handleClChangeQuantity({ quantity, orderItemId, eye, contactLensData: orderItemClData })
                        }}
                      />
                    </>
                  )}
                  <CartRecapModelPriceDataRow>
                    <CartRecapDataRowProductModel>{productBaseModelCode}</CartRecapDataRowProductModel>
                  </CartRecapModelPriceDataRow>
                  {showSubscriptionDiscountSection && (
                    <div className={clsx(styles.clSubscribedMsg)}>
                      {t('Subscriptions.Msgs.Active.SavingMessage', {
                        savingsAmount: CurrencyService.getFormattedPrice(
                          mySite.locale,
                          mySite.defaultCurrencyID,
                          subscriptionDiscount
                        ),
                      })}
                    </div>
                  )}
                  {orderItemClData && (
                    <LensDetailsSection>
                      <OrderRecapClItemLensDetails
                        orderItemClData={orderItemClData}
                        showQuantityPerEye={false}
                        alwaysShowPrescription={false}
                        onEdit={showEditLens ? onEditClick : undefined}
                      />
                      <StyledCartRecapDivider />
                    </LensDetailsSection>
                  )}
                  <ProductPriceAlgolia
                    totalBoxes={totalBoxes ?? 0}
                    adjustments={orderItem.adjustment}
                    productQuantity={[orderItem.quantity]}
                    isTotal={true}
                    showDiscountBadge={isItemSubscribed(orderItem)}
                  />
                </CartRecapDataContent>
              </CartRecapData>
            </CartRecapRightColumn>
          </CartRecapContentContainer>
        </CartRecapItemWrapper>
      </ProductContextProvider>
    </Box>
  )
}

type ActionCTAsProps = {
  handleDelete: () => void
  isRemovingCurrentItem: boolean
}

const ActionCTAs = (props: ActionCTAsProps) => {
  const { handleDelete, isRemovingCurrentItem } = props
  const { t } = useTranslation()

  return (
    <CartRecapActions>
      <CartRecapActionItem onClick={handleDelete} data-element-id="X_X_Prods_Remove">
        <span>
          {(isRemovingCurrentItem && <PreLoader fill={'dark'} withButton />) || (
            <SVGIcon library="account" name="trash-bin" size={18} />
          )}
        </span>
        <span> {t('CartRecap.Actions.Delete')}</span>
      </CartRecapActionItem>
    </CartRecapActions>
  )
}

export default CartRecapClItem
