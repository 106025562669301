import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useInstantSearch, useRange, useRefinementList } from 'react-instantsearch-hooks-web'
import { useTranslation } from 'next-i18next'

import { Link } from '@components/common/Link/Link'
import { MultipleQueriesQuery } from '@algolia/client-search'
import { SORTING_NOT_CLUSTERED } from '@constants/common'
import { SEARCH } from '@constants/routes'
import {
  ATTRIBUTES_TRANSLATED,
  INDICES_MAP,
  clearAlgoliaEventParameters,
  storeAlgoliaEventParameters,
  sendPlpEvent,
  getAlgoliaObjectIds,
} from '@features/plp/algoliaUtils'
import { IUiState } from '@features/ui/slice'
import { algoliaEventTrigger, initIndexName } from '@foundation/algolia/algoliaConfig'
import { determineAlgoliaPrice } from '@foundation/algolia/algoliaPrice'
import { getUserDetailsForAnalytics, getUserToken } from '@foundation/hooks/useAnalyticsData'
import { ProductForMonetate, monetateTrackPlp } from '@foundation/monetate/lib'
import { MONETATE_CART_ITEMS } from '@foundation/monetate/monetateConstants'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import useBreakpoints from '@hooks/useBreakpoints'
import { useProductParams } from '@hooks/useProductParams'
import { Box } from '@mui/system'
import { SearchClient } from 'algoliasearch'
import { FACETS_LIMIT } from '../../configs/catalog'
import { BreadcrumbLayout } from '../breadcrumb'
import BottomSEOBlock from './components/BottomSEOBlock'
import PlpHeaderAlgolia from './components/PlpHeader/PlpHeaderAlgolia'
import { ProductGridViewAlgolia } from './components/ProductGridView'
import TopSEOBlock from './components/TopSEOBlock'
import usePlpPlacements from './usePlpPlacements'
// TODO RESTORE import { useFrameGenius } from '../FrameGenius/FrameGeniusContext'
// TODO RESTORE import SizeAdvisorUtil from '@utils/FrameGenius/SizeAdvisorUtil'
import PlacementLayout from '@components/Cms/PlacementLayout'
import PlpDescription from '@components/PlpDescription/PlpDescription'
import { useRangeSlider } from '@components/PlpFilter/components/RangeSlider'
import ProductTypePillFilters from '@components/ProductTypePillFilters'
import { ICategory } from '@features/category/query'
import { sendSearchResultEvent } from '@foundation/analytics/tealium/lib'
import { ICommerceHclPage } from '@typesApp/cms'
import { IAlgoliaHit } from '@typesApp/product'
import { usePlpDispatch, usePlpState } from '@utils/Plp/PlpContext'
import { teaserPropsByView } from '@utils/placements'
import { getParentCatalogGroupId } from '@utils/product'
import { Breadcrumbs, getProductBreadcrumbs, sortBreadcrumbsByGroupId } from '../PagesSeo/product/Product'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { AlgoliaRecommendationsWrapper } from '@components/AlgoliaRecommendations/AlgoliaRecommendations.style'
import SuggestedProductsAlgolia from '@views/ProductDetails/components/SuggestedProductsAlgolia'
import { partition } from '@utils/arrayUtils'

const VirtualRefinementList: React.FC<{ attribute: string }> = ({ attribute }) => {
  useRefinementList({ attribute: attribute, operator: 'or' })
  return null
}

const VirtualRangeSlider: React.FC<{ customerSegment: string }> = ({ customerSegment }) => {
  useRangeSlider({ attribute: `sortPrice_${customerSegment}` })
  return null
}

const VirtualRange: React.FC<{ attribute: string }> = ({ attribute }) => {
  useRange({ attribute })
  return null
}

const PLP_PLACEMENT_KEY = 'PLP_placement_'
const HINGE_DISTANCE_RANGE = `${ATTRIBUTES_TRANSLATED}HINGE_DISTANCE_RANGE`

export interface ProductGridProps {
  cid: string
  categoryId?: string
  searchTerm?: string
  searchClient: SearchClient
  categoryFilter?: string
  categoryData?: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  plpCommerce?: ICommerceHclPage | undefined
  handleRoute?: (parameters: string[] | string) => void
  pathname?: string
}

interface AlgoliaQuerySuggestion extends Partial<IAlgoliaHit> {
  query: string
}

const getResultsWithPrice = (results: IAlgoliaHit[]): IAlgoliaHit[] => {
  return results.map(list => {
    const clusters = list.clusters?.map(cluster => ({
      ...cluster,
      x_price: { ...cluster.prices },
    }))

    return {
      ...list,
      clusters,
    }
  })
}

/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 */
const ProductGridLayoutAlgolia: React.FC<ProductGridProps> = props => {
  const {
    cid,
    searchTerm = '',
    searchClient,
    categoryFilter = '',
    categoryData,
    plpCommerce,
    handleRoute,
    pathname,
  } = props

  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const plpState = usePlpState()
  const plpDispatch = usePlpDispatch()
  const customerSegments = useCustomerSegmentsUtil()
  const { results, status, indexUiState } = useInstantSearch()

  const langCountry = plpState.locale
  const langCode = plpState.locale.replace('_', '-').toLocaleLowerCase()
  const seoData = plpState.seoData

  const [isCatalogLoading, setIsCatalogLoading] = useState<boolean>(
    plpState.firstLoad ? status === 'loading' || status === 'stalled' : false
  )
  const [monetateEventPlpPageNumber, setMonetateEventPlpPageNumber] = useState<number | undefined>()
  const [productList, setProductList] = useState(getResultsWithPrice(plpState.ungroupedHits))
  const [suggestedKeywords, setSuggestedKeywords] = useState<string[]>([])
  const [suggestedProducts, setSuggestedProducts] = useState<Partial<IAlgoliaHit>[]>([])
  const [noResultsPinnedProducts, setNoResultPinnedProducts] = useState<Partial<IAlgoliaHit>[]>([])

  const { nbHits, __isArtificial, queryID, hits, index } = results

  const sortOption = useMemo(
    () => plpState.sortOrderOptions.find(x => x.value === indexUiState.sortBy),
    [indexUiState, plpState.sortOrderOptions]
  )
  const selectedSortOption = useMemo(() => (sortOption?.id ? String(sortOption?.id) : '0'), [sortOption])

  const productsMobileListingLayout = plpState.productsMobileListingLayout
  const { generateParameters } = useProductParams()
  const parameters = useMemo(() => generateParameters(customerSegments[0]), [customerSegments, generateParameters])

  const parentCatalogGroupID = getParentCatalogGroupId(categoryData)
  let breadcrumbs: Breadcrumbs[] = []
  if (!searchTerm) {
    const breadcrumbsNotSorted = getProductBreadcrumbs(categoryData) || []
    breadcrumbs = sortBreadcrumbsByGroupId(breadcrumbsNotSorted, parentCatalogGroupID)
  }

  const { placementsPlpCommerce, plpDescription } = usePlpPlacements({ plpCommerce }, plpState.appliedFacets)
  let plpPlacements = placementsPlpCommerce?.filter(placement => placement.name.includes(PLP_PLACEMENT_KEY))

  if (indexUiState.page) {
    plpPlacements = plpPlacements.filter(placement => placement?.name !== 'PLP_placement_1')
  }

  const productListingLayout: IUiState['productsMobileListingLayout'] =
    productsMobileListingLayout === 'full' && !isDesktop ? 'full' : 'compact'
  const categoryDescription = categoryData ? categoryData[categoryData.length - 1].description : ''
  const categoryLongDescription = categoryData ? categoryData[categoryData.length - 1].longDescription : ''
  const headerTitle = indexUiState.query ? indexUiState.query : categoryDescription

  const bestsellerClusterCheck = el => el === '0'
  const currentOffset = indexUiState.page ?? 0

  const querySearchTerm = indexUiState.query ?? ''
  // TODO const originalSearchTerm = plpState.searchParams?.get(ORIGINALSEARCHTERM) ?? ''

  const hasSuggestedProducts =
    suggestedKeywords && suggestedKeywords.length > 0 && suggestedProducts && suggestedProducts.length > 0

  const sendMonetatePlpEvent = () => {
    setMonetateEventPlpPageNumber(Number(currentOffset))

    const productIds: string[] = productList.flatMap(product => product.partnumberId).map(product => product)
    const monetateCartItems =
      localStorageUtil.get(MONETATE_CART_ITEMS) ||
      '[]' /* Need to fall back to string to prevent JSON.parse from erroring */
    const productsInCart: ProductForMonetate[] = JSON.parse(monetateCartItems)
    monetateTrackPlp({ productIds, productsInCart, pageType: 'plp' })
  }

  const brandNameRough = seoData?.tokenExternalValue?.split('_').pop() || ''
  const brandName = decodeURI(brandNameRough)

  const isClustered =
    (!!indexUiState.sortBy || bestsellerClusterCheck(selectedSortOption)) &&
    !SORTING_NOT_CLUSTERED.includes(String(selectedSortOption))

  const [topPlacements, bottomPlacements] = partition(plpPlacements, placement => {
    const bottom = placement?.items?.filter(item => item.viewtype === 'recently-viewed' || item.viewtype === 'faqs')
    return !bottom.length
  })

  const showTopPlacements = topPlacements?.every(placement =>
    placement.items.every(item => {
      const { teaserTitle, teaserText } = teaserPropsByView(placement.viewtype || 'default')
      return !(item?.media?.length === 0 && item[teaserTitle] === '' && item[teaserText] === '')
    })
  )

  const selectedFacets = plpState.appliedFacets.filter(el => !el.attribute.startsWith('attributes.'))
  const appliedFiltersNumber = selectedFacets.length || 0

  const fetchUngroupedProducts = hits => {
    const { sortBy } = parameters
    const ungroupedIndexName: string = initIndexName({
      locale: langCountry,
      sortOption: sortBy ? Number(sortBy) : INDICES_MAP.BEST_SELLERS,
      isGrouped: false,
      customerSegment: customerSegments[0],
    })

    const facetFilters = new URLSearchParams(results['params']).get('facetFilters')

    const queries: MultipleQueriesQuery[] = hits.map(({ x_groupkey }) => ({
      indexName: ungroupedIndexName,
      query: searchTerm,
      params: {
        filters: `${categoryFilter ? `${categoryFilter} AND ` : ''}x_groupkey:"${x_groupkey}"`,
        facetFilters,
      },
    }))

    if (queries.length) {
      searchClient
        .multipleQueries<IAlgoliaHit>(queries)
        .then(data => {
          const hitsWithClustersAndPrices = data.results.map(result => {
            const hitsWithPrices = result.hits.map<IAlgoliaHit>((hit: IAlgoliaHit) => ({
              ...hit,
              // NOTE: temporary solution to align all product types to have x_price property
              x_price: { ...hit.prices },
            }))

            // NOTE: temporary solution to align all product types to have x_price property
            const firstProduct = { ...result.hits[0], x_price: { ...result.hits[0].prices } }
            return {
              ...firstProduct,
              clusters: hitsWithPrices,
            }
          })

          setProductList(hitsWithClustersAndPrices)
          storeAlgoliaEventParameters({
            type: 'ungrouped',
            indexName: ungroupedIndexName,
            queryID: results.queryID,
          })
          return hitsWithClustersAndPrices
        })
        .then(hitsWithClustersAndPrices => {
          const objectIDs = getAlgoliaObjectIds(hitsWithClustersAndPrices)

          algoliaEventTrigger('sendEvents', [
            {
              eventType: 'view',
              userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
              eventName: 'algolia_ungrouped_seach_result',
              index: ungroupedIndexName,
              objectIDs,
              queryID: results.queryID,
            },
          ])
        })
        .catch(() => {
          setProductList(hits)
        })
    }
    setIsCatalogLoading(false)
  }

  const fetchSuggestedKeywords = useCallback(
    (query: string) => {
      const keywords: string[] = []
      const indexName = initIndexName({ locale: langCountry, sortOption: INDICES_MAP.QUERY_SUGGESTIONS })
      const queries = [
        {
          indexName,
          query,
          params: {
            hitsPerPage: 5,
          },
        },
      ]

      searchClient.multipleQueries<AlgoliaQuerySuggestion>(queries).then(data => {
        const hits = data.results?.[0]?.hits
        if (hits && hits.length > 0) {
          hits.map(hit => keywords.push(hit.query))
        }
        setSuggestedKeywords(keywords)
      })
    },
    [langCountry, searchClient]
  )

  const fetchSuggestedProducts = useCallback(
    (query: string) => {
      const products: Partial<IAlgoliaHit>[] = []
      const indexName = initIndexName({ locale: langCountry })
      const queries = [
        {
          indexName,
          query,
        },
      ]

      searchClient.multipleQueries<IAlgoliaHit>(queries).then(data => {
        const results = data.results?.[0].hits
        if (results && results.length > 0) {
          const hits = results.map((hit: Partial<IAlgoliaHit>) => ({
            ...hit,
            x_price: { ...hit.prices },
          }))
          products.push(...hits)
        }
        setSuggestedProducts(products)
      })
    },
    [langCountry, searchClient]
  )

  const fetchNoResultsPinnedProducts = useCallback(() => {
    const products: Partial<IAlgoliaHit>[] = []
    const indexName = initIndexName({ locale: langCountry })
    const query: MultipleQueriesQuery = {
      indexName,
      query: '',
      params: {
        hitsPerPage: 12,
        ruleContexts: ['NORESULTPRODUCTS'],
      },
    }

    searchClient.multipleQueries<IAlgoliaHit>([query]).then(data => {
      const results = data.results?.[0].hits
      if (results && results.length > 0) {
        const hits = results.map((hit: Partial<IAlgoliaHit>) => ({
          ...hit,
          price: determineAlgoliaPrice(hit.prices, customerSegments, undefined, true),
          // NOTE: temporary solution to align all product types to have x_price property
          x_price: { ...hit?.prices },
        }))
        products.push(...hits)
      }
      setNoResultPinnedProducts(products)
    })
  }, [])

  useEffect(() => {
    if (!isCatalogLoading && productList?.length > 0) {
      let { analyticsData } = plpState
      getUserDetailsForAnalytics(null, analyticsData, false)

      if (querySearchTerm) {
        sendSearchResultEvent({
          common: { ...analyticsData },
          qnt: nbHits,
          products: productList as any[],
          pageSection: seoData?.identifier || '',
          searchKeyword: querySearchTerm,
          searchKeyActual: '',
        })
      } else {
        sendPlpEvent({
          common: {
            ...analyticsData,
          },
          qnt: nbHits,
          products: productList,
          pageSection: seoData?.identifier || '',
        })
      }
    }
  }, [isCatalogLoading, querySearchTerm])

  // Monetate tracking on initial load
  useEffect(() => {
    const timer = setTimeout(sendMonetatePlpEvent, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // Monetate tracking when clicking on pagination
  useEffect(() => {
    if (
      productList &&
      monetateEventPlpPageNumber !== undefined &&
      Number(currentOffset) !== monetateEventPlpPageNumber
    ) {
      sendMonetatePlpEvent()
    }
  }, [productList])

  useEffect(() => {
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'])
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'], 'grouped')
    clearAlgoliaEventParameters(['queryID', 'indexName', 'position'], 'ungrouped')
  }, [])

  useEffect(() => {
    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned yet.
    if (!__isArtificial && (plpState.firstLoad || !plpState.openDrawerFilters)) {
      if (queryID && index) {
        storeAlgoliaEventParameters({
          type: 'grouped',
          indexName: index,
          queryID: queryID,
        })
      }

      if (isClustered) {
        fetchUngroupedProducts(hits)
        plpDispatch({
          type: 'SET_MULTIPLE',
          payload: {
            shouldLoadUngrouped: false,
            firstLoad: false,
          },
        })
        if (hits.length > 0) {
          const splittedHits = hits.reduce((all, one, i) => {
            const ch = Math.floor(i / FACETS_LIMIT)
            all[ch] = [].concat(all[ch] || [], one)
            return all
          }, [])

          splittedHits.forEach(sh => {
            const objectIDs = getAlgoliaObjectIds(sh)

            algoliaEventTrigger('sendEvents', [
              {
                eventType: 'view',
                userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
                eventName: 'algolia_grouped_seach_result',
                index,
                objectIDs,
                queryID: queryID,
              },
            ])
          })
        } else if (results.getRefinements().length > 0) {
          algoliaEventTrigger('sendEvents', [
            {
              eventType: 'view',
              userToken: window.utag_data?.User_Email_MD5 || getUserToken(),
              eventName: 'algolia_grouped_seach_result',
              index,
              queryID: queryID,
              filters: results.getRefinements().map(filter => filter.attributeName + ':' + filter.name),
            },
          ])
        }
      } else {
        if (hits.length > 0) {
          const hitsWithPrices = hits.map(hit => {
            return {
              ...hit,
              price: determineAlgoliaPrice(hit.prices, customerSegments, undefined, true),
              // NOTE: temporary solution to align all product types to have x_price property
              x_price: { ...hit?.prices },
            }
          })
          setIsCatalogLoading(false)
          setProductList(hitsWithPrices)
        }
      }

      if (results.getRefinements().length > 0) {
        sessionStorage.setItem(
          'PLPFiltersApplied',
          JSON.stringify(results.getRefinements().map(filter => filter.attributeName + ':' + filter.name))
        )
      }
    }
  }, [results, plpState.shouldLoadUngrouped])

  /**
   * Display query suggestions when there are no results from the initial search.
   */
  useEffect(() => {
    if (!isCatalogLoading && querySearchTerm && nbHits === 0) {
      // reset suggested products
      setSuggestedProducts([])
      fetchSuggestedKeywords(querySearchTerm)
    }
  }, [querySearchTerm, nbHits])

  /**
   * Search based on suggested keyword
   */
  useEffect(() => {
    if (suggestedKeywords && suggestedKeywords.length > 0) {
      const firstSuggestion = suggestedKeywords[0]
      fetchSuggestedProducts(firstSuggestion)
    }
  }, [suggestedKeywords])

  useEffect(() => {
    fetchNoResultsPinnedProducts()
  }, [])

  return (
    <>
      {Object.keys(indexUiState.refinementList || { fake_refinement_attribute: 'fake_refinement_attribute' }).map(
        refinementList => (
          <VirtualRefinementList key={refinementList} attribute={refinementList} />
        )
      )}
      <VirtualRangeSlider customerSegment={customerSegments[0]} />
      <VirtualRange attribute={HINGE_DISTANCE_RANGE} />
      <BreadcrumbLayout
        breadcrumbsList={breadcrumbs}
        cid={cid}
        langCode={langCode}
        searchTermText={`${t('ProductGrid.Labels.searchResults', { total: nbHits })}`}
      />
      <PlacementLayout placements={topPlacements} loading={false} isPLP />
      <PlpHeaderAlgolia
        title={headerTitle}
        catalogLoading={isCatalogLoading}
        searchTerm={searchTerm || ''}
        category={categoryData}
        isPlacementsBetween={showTopPlacements}
        productTypePillFilters={cid.includes('search-results') ? <ProductTypePillFilters /> : undefined}
        breadcrumbsList={breadcrumbs}
        handleRoute={handleRoute}
        pathname={pathname}
      />
      <TopSEOBlock
        isEnabled={plpState.isPlpSeoEnabled.top}
        currentOffset={currentOffset}
        brandName={brandName}
        title={categoryDescription}
        description={categoryLongDescription || ''}
      />
      <ProductGridViewAlgolia
        hasSelectedFilters={appliedFiltersNumber > 0}
        isClustered={isClustered}
        isCatalogLoading={isCatalogLoading}
        products={productList}
        productsCount={nbHits}
        dynamicContentBanners={placementsPlpCommerce}
        productListingLayout={productListingLayout}
      >
        {!isCatalogLoading && nbHits === 0 && !!searchTerm && (
          <>
            <Box sx={{ paddingX: { xs: '16px', md: '64px' }, paddingBottom: '24px' }} className="suggested-keywords">
              <h4>
                {t('ProductGrid.Labels.noMatches', {
                  searchTerm: searchTerm.replace('*', ''),
                })}
              </h4>
              <p>
                {t('ProductGrid.Labels.searchAgain', {
                  searchTerm: searchTerm.replace('*', ''),
                })}
              </p>

              {suggestedKeywords.length > 0 && (
                <>
                  {t('ProductGrid.Labels.suggestion')}
                  <br />
                  {suggestedKeywords?.map((keyword: string, index: number) => (
                    <Link
                      key={keyword}
                      href={SEARCH + '?query=' + keyword}
                      onClick={() => {}}
                      className="suggestion-link"
                      id={`productGrid_a_22_${index}_${cid}`}
                    >
                      {keyword} <br />
                    </Link>
                  ))}
                </>
              )}
            </Box>

            <AlgoliaRecommendationsWrapper>
              <SuggestedProductsAlgolia
                products={hasSuggestedProducts ? suggestedProducts : noResultsPinnedProducts}
                title={t('ProductGrid.Recommendations.youMayAlsoLike')}
              />
            </AlgoliaRecommendationsWrapper>
          </>
        )}
      </ProductGridViewAlgolia>
      {plpDescription.numFound > 0 && <PlpDescription placements={plpDescription.result} />}
      {bottomPlacements && <PlacementLayout placements={bottomPlacements} loading={false} isPLP />}
      <BottomSEOBlock
        // isEnabled={plpState.isPlpSeoEnabled.bottom}
        isEnabled={!!categoryDescription && !!categoryLongDescription}
        title={categoryDescription}
        description={categoryLongDescription || ''}
      />
    </>
  )
}

//TODO RESTORE ? export default trackWindowScroll(ProductGridLayoutAlgolia)
export default ProductGridLayoutAlgolia
